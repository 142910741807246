/** @jsxImportSource @emotion/react */
import { css } from "@emotion/core";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import cn from "classnames";
import {
  useFilters,
  usePagination,
  useSortBy,
  useTable,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import {
  Button,
  ButtonGroup,
  Input,
  Popover,
  PopoverBody,
  PopoverHeader,
} from "reactstrap";
import {
  DefaultColumnFilter,
  complexTextFilterFn,
  selectFilterFn,
  dateFilterFn,
} from "./filters";
import {
  faDownload,
  faFilter,
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./DetailTable.scss";

function cleanId(x) {
  return x.replace(" ", "_").replace(".", "_").replace("#", "_");
}

const filterTypes = {
  [undefined]: complexTextFilterFn,
  [null]: complexTextFilterFn,
  multiSelect: selectFilterFn,
  dateRange: dateFilterFn,
};

const defaultColumn = {
  Filter: DefaultColumnFilter,
};

const DetailTable = ({
  columns,
  data,
  onChange,
  onFilter,
  initialFilters = [],
  initialSort = [],
  setFilterSetter,
  setClearFilters,
  onExport,
}) => {
  const [popover, setPopover] = useState(null);

  const closePopover = useCallback(() => {
    setPopover(null);
  }, [setPopover]);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, filters },
    rows,
    setFilter,
    setAllFilters,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { filters: initialFilters, sortBy: initialSort },
    },
    useFilters,
    useSortBy,
    usePagination,
    useBlockLayout,
    useResizeColumns
  );

  const originalRows = useMemo(() => rows.map((x) => x.original), [rows]);

  console.debug("DetailTable:render", { filters });

  useEffect(() => {
    if (setFilterSetter) {
      console.debug("DetailTable:setFilterSetter");
      setFilterSetter(setFilter);
    }
  }, [setFilterSetter, setFilter]);

  useEffect(() => {
    if (setClearFilters) {
      console.debug("DetailTable:setClearFilters");
      // pass function because otherwise parent thinks its a state updater
      setClearFilters(() => setAllFilters([]));
    }
  }, [setClearFilters, setAllFilters]);

  useEffect(() => {
    // Let the parent know what filters are now active
    if (onFilter) {
      console.debug("Filtering table", filters);
      onFilter(filters);
    }
  }, [filters, onFilter]);

  useEffect(() => onChange?.(originalRows), [originalRows, onChange]);

  // Render the UI for your table
  return (
    <React.Fragment>
      <div {...getTableProps()} className="table detail-table">
        <div>
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps()} className="th">
                  {column.render("Header")}
                  {column.canSort && (
                    <span {...column.getSortByToggleProps()}>
                      {" "}
                      <FontAwesomeIcon
                        className={cn("column-action", {
                          inactive: !column.isSorted,
                        })}
                        icon={
                          column.isSorted
                            ? column.isSortedDesc
                              ? faSortDown
                              : faSortUp
                            : faSort
                        }
                      />
                    </span>
                  )}
                  {column.canFilter && (
                    <React.Fragment>
                      {" "}
                      <FontAwesomeIcon
                        className={cn("column-action", {
                          inactive: !column.filterValue,
                        })}
                        icon={faFilter}
                        id={`popover-${cleanId(column.id)}`}
                      />
                      <Popover
                        placement="bottom"
                        // turning flip off is required to avoid infinite loop
                        // https://github.com/reactstrap/reactstrap/issues/1482
                        flip={false}
                        isOpen={popover === cleanId(column.id)}
                        target={`popover-${cleanId(column.id)}`}
                        toggle={() =>
                          setPopover(
                            popover !== cleanId(column.id)
                              ? cleanId(column.id)
                              : null
                          )
                        }
                        className="filter-popover"
                      >
                        <PopoverHeader>Filter {column.Header}</PopoverHeader>
                        <PopoverBody>
                          {column.render("Filter")}
                          <Button onClick={closePopover}>Close</Button>
                        </PopoverBody>
                      </Popover>
                    </React.Fragment>
                  )}
                  <div
                    {...column.getResizerProps()}
                    className={cn("resizer", {
                      isResizing: column.isResizing,
                    })}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="tbody">
          {page.map(
            (row) =>
              prepareRow(row) || (
                <div {...row.getRowProps()} className="tr">
                  {row.cells.map((cell) => (
                    <div {...cell.getCellProps()} className="td">
                      {cell.render("Cell")}
                    </div>
                  ))}
                </div>
              )
          )}
        </div>
      </div>
      <div className="table-pagination">
        <ButtonGroup>
          <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </Button>{" "}
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </Button>{" "}
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </Button>{" "}
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>{" "}
        </ButtonGroup>
        <div>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </div>
        <Input
          css={css`
            width: 150px;
          `}
          type="select"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Input>

        {onExport && (
          <Button className="ml-3" onClick={onExport}>
            <FontAwesomeIcon icon={faDownload} /> Export as CSV
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};

// DetailTable.whyDidYouRender = true;

export default React.memo(DetailTable);
