/** @jsxImportSource @emotion/react */
import { Fragment, useState } from "react";
import { MAX_COORDINATION_RESULTS } from "../common/useCoordination";
import { css } from "@emotion/core";
import { Label } from "reactstrap";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const CoordinationOption = ({
  searchId,
  coordinationPendingCount,
  numResults,
  minCoordination,
  setMinCoordination,
}) => {
  const [value, setValue] = useState(100 - minCoordination);

  if (!searchId) {
    return null;
  }

  if (numResults > MAX_COORDINATION_RESULTS) {
    return (
      <span className="ml-3">Too many results for coordination detection</span>
    );
  } else {
    return (
      <Fragment>
        <div
          css={css`
            width: 300px;
            margin-left: 20px;
          `}
          className="d-flex"
        >
          <div>
            <Label>Coordination Threshold</Label>
          </div>
          <div
            css={css`
              width: 100px;
              padding-top: 3px;
            `}
            className="ml-3"
          >
            <Slider
              reverse
              max={100}
              min={0}
              value={value}
              onChange={setValue}
              onAfterChange={(v) => setMinCoordination(100 - v)}
            />
          </div>
        </div>
        {coordinationPendingCount > 0 && (
          <span className="ml-3">
            Running detection ({coordinationPendingCount} remaining)...
          </span>
        )}
      </Fragment>
    );
  }
};

export default CoordinationOption;
