/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { components } from "react-select";
import AsyncSelect from "react-select/async";

export const liftValue = (x) => ({ label: x, value: x });

export const defaultColumnOptions = (schema) =>
  schema.filter((x) => x.default).map((x) => liftValue(x.Header));

// https://github.com/JedWatson/react-select/issues/2790#issuecomment-460636017
export const ValueContainer = ({ children, getValue, ...props }) => {
  const length = getValue().length;

  let msg = `${length} options selected`;
  if (length === 1) {
    let detailMsg = getValue()[0].label;
    if (detailMsg.length <= msg.length) {
      msg = detailMsg;
    }
  } else if (length === 2) {
    const detailMsg = `${getValue()[0].label} and ${getValue()[1].label}`;
    if (detailMsg.length <= msg.length) {
      msg = detailMsg;
    }
  }
  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.menuIsOpen && length ? msg : ""}
      {!length && children[0] && React.cloneElement(children[0])}
      {children[1] && React.cloneElement(children[1])}
    </components.ValueContainer>
  );
};

const Option = (props) => {
  const {
    children,
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
    theme: { colors },
  } = props;
  return (
    <div
      css={{
        ...getStyles("option", props),
        backgroundColor: isFocused
          ? colors.primary
          : isSelected
          ? colors.primary25
          : "transparent",
        color: isDisabled
          ? colors.neutral20
          : isSelected
          ? colors.neutral90
          : "inherit",
        ":active": {
          backgroundColor:
            !isDisabled && (isSelected ? colors.primary : colors.primary50),
        },
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
      className={cx(
        {
          option: true,
          "option--is-disabled": isDisabled,
          "option--is-focused": isFocused,
          "option--is-selected": isSelected,
        },
        className
      )}
      ref={innerRef}
      {...innerProps}
    >
      <div
        css={css`
          flex: 0 0 23px;
        `}
      >
        {isSelected && <FontAwesomeIcon icon={faCheck} />}
      </div>
      <div>{children}</div>
    </div>
  );
};

export const CompactMultiSelect = (props) => (
  <Select
    isMulti
    closeMenuOnSelect={false}
    hideSelectedOptions={false}
    components={{ ValueContainer, Option }}
    {...props}
  />
);

export const CompactAsyncSelect = (props) => (
  <AsyncSelect
    isMulti={false}
    cacheOptions
    closeMenuOnSelect={true}
    hideSelectedOptions={false}
    components={{ Option }}
    noOptionsMessage={({ inputValue }) =>
      inputValue.length ? "No results found" : "Type to search for results"
    }
    {...props}
  />
);

export const CompactAsyncMultiSelect = (props) => (
  <AsyncSelect
    isMulti
    cacheOptions
    closeMenuOnSelect={false}
    hideSelectedOptions={false}
    components={{ ValueContainer, Option }}
    noOptionsMessage={({ inputValue }) =>
      inputValue.length ? "No results found" : "Type to search for results"
    }
    {...props}
  />
);
