import URI from "urijs";

const rootUrl = process.env.REACT_APP_API_BASE_URL || ""; // set if not using proxy

export class RequestError extends Error {
  constructor(response) {
    super();
    this.name = "RequestError";
    this.response = response;
  }
}

export function getAPI(url, token, query = {}) {
  const uri = new URI(`${rootUrl}${url}`).search(query);

  return fetch(uri, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Token ${token}` : null,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new RequestError(response);
    }
  });
}

export function postAPI(url, data = {}, { token } = {}) {
  return fetch(`${rootUrl}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Token ${token}` : null,
    },
    body: JSON.stringify(data),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new RequestError(response);
    }
  });
}

export function patchAPI(url, data = {}, { token } = {}) {
  return fetch(`${rootUrl}${url}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Token ${token}` : null,
    },
    body: JSON.stringify(data),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new RequestError(response);
    }
  });
}

export function deleteAPI(url, token) {
  return fetch(`${rootUrl}${url}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Token ${token}` : null,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new RequestError(response);
    }
  });
}
