import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { Bar } from "react-chartjs-2";
import Worker from "workerize-loader!../workers/chartWorker"; // eslint-disable-line import/no-webpack-loader-syntax
import Humanize from "humanize-plus";

const worker = Worker();

const Range = ({ range }) => {
  if (!range) {
    return "–";
  }
  const [lower, upper] = range;
  if (Number.isNaN(upper)) {
    return `${Humanize.intComma(lower)}+`;
  } else {
    return `${Humanize.intComma(lower)} – ${Humanize.intComma(upper)}`;
  }
};

const Chart = ({ data }) => {
  const [values, setValues] = useState({ labels: [], datasets: [] });
  const [summary, setSummary] = useState({
    spend: undefined,
    impressions: undefined,
  });

  useAsyncEffect(
    async (isMounted) => {
      const {
        demographics: datasets,
        spend,
        impressions,
      } = await worker.calculateDemographics(data);
      if (!isMounted()) return;
      setValues(datasets);
      setSummary({ spend, impressions });
    },
    [data]
  );

  return (
    <>
      <div>
        <Bar
          data={values}
          width={100}
          height={300}
          options={{ maintainAspectRatio: false }}
        />
      </div>
      <div className="d-flex font-weight-bold mt-1">
        <div className="flex-grow-1">
          Total Spend: <Range range={summary.spend} />
        </div>
        <div className="flex-grow-1">
          Total Impressions: <Range range={summary.impressions} />
        </div>
      </div>
    </>
  );
};

export default React.memo(Chart);
