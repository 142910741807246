/** @jsxImportSource @emotion/react */

import { useCallback, useContext } from "react";
import UserContext from "../../common/UserContext";
import { postAPI } from "../../../WebAPI";
import { toastr } from "react-redux-toastr";
import { handleError } from "../../common/hooks";
import { Button, UncontrolledAlert } from "reactstrap";

function useConnectFacebook() {
  const { updateAuth, token, ...user } = useContext(UserContext);
  return useCallback(() => {
    if (window.FB) {
      window.FB.login(function ({ authResponse }) {
        if (!authResponse) {
          toastr.error(
            "Facebook not loaded",
            "Did not receive an authentication response from Facebook"
          );
          return;
        }

        updateAuth({ ...user, facebookToken: authResponse.accessToken });
        postAPI(
          "/api/facebook/associate/",
          { facebookToken: authResponse.accessToken },
          { token }
        )
          .then(() => {
            toastr.success("Connected Facebook account");
          })
          .catch((error) => {
            return handleError(error);
          });
      });
    } else {
      toastr.error(
        "Facebook not loaded",
        "The FB SDK could not be loaded. Disable any extensions that might be blocking Facebook (e.g., Ad Blockers)"
      );
    }
  }, [user, token, updateAuth]);
}

export const ConnectFacebook = () => {
  const connectFB = useConnectFacebook();

  return (
    <UncontrolledAlert color="primary alert-outline">
      <div className="alert-message">
        <h4 className="alert-heading">Connect Facebook Account</h4>
        <p>
          Please connect a Facebook account that has been approved/verified to
          run political ads. If you do not connect your account, you will not be
          able to access links to ads and pages on Facebook.
        </p>
        <hr />
        <Button color="primary" onClick={connectFB}>
          Connect Facebook
        </Button>
      </div>
    </UncontrolledAlert>
  );
};

export default useConnectFacebook;
