/** @jsxImportSource @emotion/react */
import { css } from "@emotion/core";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  CustomFileInput,
} from "reactstrap";
import React, { useCallback, useContext, useMemo, useState } from "react";
import * as _ from "lodash";
import { faExternalLinkAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAPI } from "../../WebAPI";
import UserContext from "../common/UserContext";
import { CompactAsyncSelect } from "../common/CustomSelect";
import { useDebouncedCallback } from "use-debounce";
import Papa from "papaparse";
import { toastr } from "react-redux-toastr";

import "./KnownEntities.scss";

const Entry = ({ page, readonly, onDelete }) => {
  const [show, setShow] = useState(false);
  return (
    <div
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      className="py-2"
    >
      {!readonly && onDelete && show && (
        <Button
          color="danger"
          className="mr-3"
          size="xs"
          onClick={() => onDelete(page.value)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      )}
      <a
        href={`https://www.facebook.com/${page.value}/`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {page.label} <FontAwesomeIcon icon={faExternalLinkAlt} />
      </a>
    </div>
  );
};

function parseFile(rows) {
  return rows.map((x, i) => {
    const pageId = x["Page Id"];
    const pageName = x["Page Name"];
    if (!pageId || !pageName) {
      throw new Error(`Row ${i + 2} missing either Page Id or Page Name`);
    }
    return { value: pageId, label: pageName };
  });
}

const PagesCard = ({ pages, readonly, onAdd, onDelete }) => {
  const [newValue, setNewValue] = useState(undefined);
  const ordered = useMemo(() => _.sortBy(pages, (x) => x.label), [pages]);
  const { token } = useContext(UserContext);
  const [uploadSeq, setSeq] = useState(0);

  const [loadPages] = useDebouncedCallback(
    (query) => getAPI("/api/facebook/pages/", token, { q: query, limit: 20 }),
    50
  );

  const upload = useCallback(
    (x) => {
      if (x.target.files.length) {
        setSeq((x) => x + 1);
        Papa.parse(x.target.files[0], {
          header: true,
          complete: (results) => {
            try {
              const pages = parseFile(results.data);
              onAdd(pages);
            } catch (ex) {
              toastr.error("Import Error", ex.toString());
            }
          },
        });
      }
    },
    [onAdd]
  );

  return (
    <Card>
      <CardHeader className="pb-1">
        <CardTitle tag="h5">Facebook Pages</CardTitle>
      </CardHeader>
      <CardBody className="pt-1">
        {!readonly && (
          <Row>
            <Col className="mb-3">
              <Form inline>
                <FormGroup className="mr-2">
                  <Label for="watchlist-name" className="mr-2">
                    Add Page
                  </Label>
                  <CompactAsyncSelect
                    id="pages-select"
                    className="react-select-container ml-2"
                    classNamePrefix="react-select"
                    onChange={setNewValue}
                    value={newValue}
                    loadOptions={loadPages}
                  />
                </FormGroup>
                <Button
                  disabled={newValue == null}
                  onClick={(e) => {
                    e.preventDefault();
                    onAdd([newValue]);
                    setNewValue(null);
                  }}
                >
                  Add
                </Button>

                <FormGroup className="ml-3">
                  <div
                    css={css`
                      width: 200px;
                    `}
                  >
                    <CustomFileInput
                      key={uploadSeq}
                      className="upload-input"
                      id="pageFile"
                      name="pageFile"
                      label="Import CSV"
                      onChange={upload}
                    />
                  </div>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        )}

        <Row>
          {!ordered.length && <Col>No pages selected</Col>}
          {ordered.map((x) => (
            <Col sm={3} className="mb-2" key={x.value}>
              <Entry page={x} onDelete={onDelete} readonly={readonly} />
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  );
};

export default PagesCard;
