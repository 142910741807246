/** @jsxImportSource @emotion/react */
import { css } from "@emotion/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import UserContext from "../common/UserContext";
import { getAPI } from "../../WebAPI";
import {
  CompactMultiSelect,
  CompactAsyncMultiSelect,
} from "../common/CustomSelect";
import countryList from "country-list";
import * as _ from "lodash";
import FlagContext from "../common/FlagContext";
import useAsyncEffect from "use-async-effect";

const COUNTRY_PREFS = {
  US: 0,
  GB: 1,
  CA: 2,
};

const LABEL_OVERRIDE = {
  US: "United States",
  GB: "United Kingdom",
};

const COUNTRY_OPTIONS = _.sortBy(
  countryList.getData().map(({ code, name }) => ({
    value: code,
    label: LABEL_OVERRIDE[code] ?? name,
  })),
  [(x) => COUNTRY_PREFS[x.value], (x) => x.label]
);

const AD_STATUS_OPTIONS = [
  { value: "ALL", label: "All" },
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "Inactive" },
];

const PUBLISHER_PLATFORM_OPTIONS = [
  { value: "FACEBOOK", label: "Facebook" },
  { value: "INSTAGRAM", label: "Instagram" },
  { value: "WHATSAPP", label: "WhatsApp" },
  { value: "MESSENGER", label: "Messenger" },
  { value: "AUDIENCE_NETWORK", label: "Audience Network" },
];

const IMPRESSION_CONDITION_OPTIONS = [
  { value: "HAS_IMPRESSIONS_YESTERDAY", label: "Yesterday" },
  { value: "HAS_IMPRESSIONS_LAST_7_DAYS", label: "7 Days" },
  { value: "HAS_IMPRESSIONS_LAST_30_DAYS", label: "30 Days" },
  { value: "HAS_IMPRESSIONS_LAST_90_DAYS", label: "90 Days" },
  { value: "HAS_IMPRESSIONS_LIFETIME", label: "Forever" },
];

export const blankQuery = {
  query: "",
  countries: [COUNTRY_OPTIONS[0]],
  platforms: [],
  status: AD_STATUS_OPTIONS[0],
  pages: [],
  impressionCondition: IMPRESSION_CONDITION_OPTIONS[2],
  ad_delivery_date_max: null,
  ad_delivery_date_min: null,
  delivery_by_region: [],
  search_type: "KEYWORD_EXACT_PHRASE",
};

function useThrottle() {
  const { token } = useContext(UserContext);
  const [{ accessTime, objectCountPct }, setState] = useState({});

  useAsyncEffect(async (isMounted) => {
    const { access_time_min, object_count_pct } = await getAPI(
      "/api/facebook/throttle/",
      token
    );
    if (!isMounted()) return;
    setState({ accessTime: access_time_min, objectCountPct: object_count_pct });
  }, []);

  return { accessTime, objectCountPct };
}

const SearchBar = ({
  isSearching,
  isWatchlist,
  onSearch,
  onChange,
  initialQuery = blankQuery,
}) => {
  const { token } = useContext(UserContext);
  const [query, setQuery] = useState(initialQuery);
  const { flags } = useContext(FlagContext);
  const { accessTime, objectCountPct } = useThrottle();

  useEffect(() => {
    if (onChange) {
      onChange(query);
    }
  }, [query, onChange]);

  const loadPages = useMemo(
    () =>
      _.debounce(
        (query) =>
          getAPI("/api/facebook/pages/", token, { q: query, limit: 25 }),
        100,
        { leading: true }
      ),
    [token]
  );
  const loadRegions = useMemo(
    () =>
      _.debounce(
        (query) =>
          getAPI(
            "/api/facebook/regions/",
            token,
            { q: query, limit: 25 },
            { leading: true }
          ),
        100
      ),
    [token]
  );

  return (
    <Row>
      <Col lg="12">
        <Row className="mb-3">
          <Col lg="12">
            <Form inline>
              <FormGroup className="mr-2">
                <Label for="search-terms">Search Terms</Label>
                <Input
                  css={css`
                    min-width: 300px;
                  `}
                  className="ml-2"
                  id="search-terms"
                  placeholder="Enter search terms..."
                  value={query.query}
                  onChange={(e) =>
                    setQuery((x) => ({ ...x, query: e.target.value }))
                  }
                />
              </FormGroup>

              <FormGroup className="mr-2">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={query.search_type === "KEYWORD_EXACT_PHRASE"}
                    onChange={() => {
                      const isExact =
                        query.search_type === "KEYWORD_EXACT_PHRASE";
                      setQuery((x) => ({
                        ...x,
                        search_type: isExact
                          ? "KEYWORD_UNORDERED"
                          : "KEYWORD_EXACT_PHRASE",
                      }));
                    }}
                  />
                  Exact Phrase
                </Label>
              </FormGroup>

              <span className="mr-2">AND/OR</span>
              <FormGroup className="mr-2">
                <Label for="pages-select">Select Pages</Label>
                <CompactAsyncMultiSelect
                  id="pages-select"
                  className="react-select-container ml-2"
                  classNamePrefix="react-select"
                  onChange={(pages) => setQuery((x) => ({ ...x, pages }))}
                  value={query.pages}
                  loadOptions={loadPages}
                  defaultOptions={query.pages}
                  placeholder="All Pages"
                />
              </FormGroup>
              <Button
                disabled={
                  isSearching ||
                  (!query.query && (query.pages || []).length === 0)
                }
                onClick={() => onSearch(query)}
              >
                Search
              </Button>
              {flags.includes("telemetry") && objectCountPct != null && (
                <React.Fragment>
                  <span className="ml-1">Quota Usage: {objectCountPct}%</span>
                  {accessTime > 0 && (
                    <span className="ml-1">
                      Time Until Available (min.): {accessTime}
                    </span>
                  )}
                </React.Fragment>
              )}
            </Form>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Form inline>
              <FormGroup className="mr-2">
                <Label for="country-select">Countries</Label>
                <CompactMultiSelect
                  css={css`
                    min-width: 225px;
                    max-width: 225px;
                  `}
                  id="country-select"
                  className="react-select-container ml-2"
                  classNamePrefix="react-select"
                  options={COUNTRY_OPTIONS}
                  value={query.countries}
                  placeholder="Any Country"
                  onChange={(countries) =>
                    setQuery((x) => ({ ...x, countries }))
                  }
                  isClearable={false}
                />
              </FormGroup>
              {flags.includes("region-search") && (
                <FormGroup className="mr-2">
                  <Label for="region-select">Regions</Label>
                  <CompactAsyncMultiSelect
                    css={css`
                      min-width: 225px;
                      max-width: 225px;
                    `}
                    id="region-select"
                    className="react-select-container ml-2"
                    classNamePrefix="react-select"
                    onChange={(regions) => setQuery((x) => ({ ...x, regions }))}
                    value={query.regions}
                    loadOptions={loadRegions}
                    defaultOptions={query.regions}
                    placeholder="Any Region"
                  />
                </FormGroup>
              )}
              <FormGroup className="mr-2">
                <Label for="platform-select">Platform</Label>
                <CompactMultiSelect
                  id="platform-select"
                  css={css`
                    max-width: 180px;
                  `}
                  options={PUBLISHER_PLATFORM_OPTIONS}
                  className="react-select-container ml-2"
                  classNamePrefix="react-select"
                  value={query.platforms}
                  onChange={(platforms) =>
                    setQuery((x) => ({ ...x, platforms }))
                  }
                  placeholder="All Platforms"
                />
              </FormGroup>
              {!isWatchlist && (
                <FormGroup className="mr-2">
                  <Label for="status-select">Ad Status</Label>
                  <Select
                    id="status-select"
                    className="react-select-container ml-2"
                    classNamePrefix="react-select"
                    options={AD_STATUS_OPTIONS}
                    value={query.status}
                    onChange={(status) => setQuery((x) => ({ ...x, status }))}
                  />
                </FormGroup>
              )}
            </Form>
          </Col>
        </Row>
        {!isWatchlist && (
          <Row className="mt-3">
            <Col lg="12">
              <Form inline>
                <FormGroup className="mr-2">
                  <Label for="impression-condition-select">Last Seen</Label>
                  <Select
                    isClearable={true}
                    id="impression-condition-select"
                    options={IMPRESSION_CONDITION_OPTIONS}
                    className="react-select-container ml-2"
                    classNamePrefix="react-select"
                    value={query.impressionCondition}
                    onChange={(impressionCondition) =>
                      setQuery((x) => ({
                        ...x,
                        impressionCondition,
                        ad_delivery_date_min: "",
                        ad_delivery_date_max: "",
                      }))
                    }
                  />
                </FormGroup>

                <span className="mr-2">OR</span>
                <span className="mr-2">Delivery Range (Inclusive)</span>

                <FormGroup className="mr-2">
                  <Input
                    id="ad_delivery_date_min"
                    type="date"
                    value={query.ad_delivery_date_min ?? ""}
                    onChange={(e) => {
                      console.debug("ad_delivery_date_min", e.target.value);
                      setQuery((x) => ({
                        ...x,
                        ad_delivery_date_min: e.target.value,
                        impressionCondition: null,
                      }));
                    }}
                  />
                </FormGroup>

                <span className="mr-2">&mdash;</span>

                <FormGroup className="mr-2">
                  <Input
                    id="ad_delivery_date_max"
                    type="date"
                    value={query.ad_delivery_date_max ?? ""}
                    onChange={(e) => {
                      console.debug("ad_delivery_date_max", e.target.value);
                      setQuery((x) => ({
                        ...x,
                        ad_delivery_date_max: e.target.value,
                        impressionCondition: null,
                      }));
                    }}
                  />
                </FormGroup>
              </Form>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default SearchBar;
