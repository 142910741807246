import React, { useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { HorizontalBar } from "react-chartjs-2";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Worker from "workerize-loader!../workers/chartWorker"; // eslint-disable-line import/no-webpack-loader-syntax
import DetailTable from "../common/DetailTable";
import { firstBy } from "thenby";
import { MultiSelectColumnFilter } from "../common/filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faTable } from "@fortawesome/free-solid-svg-icons";
import Range from "../common/Range";
import { chartTable, entityTable } from "./export";

const worker = Worker();

const columnFactory = (setFilter) => [
  {
    Header: "Entity",
    accessor: "entity",
    Filter: MultiSelectColumnFilter,
    filter: "multiSelect",
  },
  {
    Header: "# Ads",
    accessor: "numAds",
    disableFilters: true,
  },
  {
    Header: "Spend",
    accessor: "spend",
    Cell: ({ cell: { value } }) => <Range range={value} />,
    sortType: firstBy((x) => +x.values.spend[0]),
    disableFilters: true,
  },
  {
    Header: "Impressions",
    accessor: "impressions",
    Cell: ({ cell: { value } }) => <Range range={value} />,
    sortType: firstBy((x) => x.values.impressions[0]),
    disableFilters: true,
  },
  {
    id: "filter",
    Header: "Action",
    accessor: "entity",
    Cell: ({ cell: { value } }) => (
      <Button
        color="info"
        size="sm"
        onClick={() => setFilter("funding_entity", value)}
        disabled={!setFilter}
      >
        <FontAwesomeIcon icon={faFilter} /> Set Filter
      </Button>
    ),
    disableFilters: true,
    disableSort: true,
  },
];

const initialSort = [{ id: "spend", desc: true }];

const TableModal = ({ data, toggle, setFilter }) => {
  const [records, setRecords] = useState([]);
  const columns = useMemo(() => columnFactory(setFilter), [setFilter]);

  useAsyncEffect(
    async (isMounted) => {
      const records = await worker.topEntitiesData(data);
      if (!isMounted()) return;
      setRecords(records);
    },
    [data]
  );

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={true} toggle={() => toggle(false)} scrollable>
        <ModalHeader toggle={() => toggle(false)}>
          Entity Spend and Impressions
        </ModalHeader>
        <ModalBody>
          <DetailTable
            columns={columns}
            data={records}
            initialFilters={[]}
            initialSort={initialSort}
            onExport={() =>
              chartTable(
                "entities",
                [{ label: "Entity", value: "entity" }],
                records
              )
            }
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const EntityChart = ({ data, setFilter }) => {
  const [dataset, setDataset] = useState({ labels: [], datasets: [] });
  const [showTable, setShowTable] = useState(false);

  useAsyncEffect(
    async (isMounted) => {
      const urls = await worker.calculateTopEntities(data);
      if (!isMounted()) return;
      setDataset({
        labels: urls.map(({ entity }) => entity),
        datasets: [
          {
            label: "# Ads",
            backgroundColor: urls.map(() => "rgb(56, 108, 176)"),
            data: urls.map(({ count }) => count),
          },
        ],
      });
    },
    [data]
  );

  return (
    <>
      <div>
        <HorizontalBar
          data={dataset}
          width={100}
          height={300}
          options={{
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: "# Ads",
                  },
                },
              ],
            },
          }}
        />
      </div>
      <div>
        {showTable && (
          <TableModal
            data={data}
            setFilter={setFilter}
            toggle={() => setShowTable(false)}
          />
        )}
        <Button color="link" onClick={() => setShowTable(true)}>
          <FontAwesomeIcon icon={faTable} /> View Table
        </Button>
      </div>
    </>
  );
};

export default React.memo(EntityChart);
