/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useMemo, useState, useContext } from "react"; // eslint-disable-line
import { css } from "@emotion/core";
import SearchBar, { blankQuery, useLatestDate } from "./SearchBar";
import { useSearch, SearchError, useJSONL } from "../common/hooks";
import DetailTable from "../common/DetailTable";
import { normalizeFilters } from "../common/filters";
import {
  ButtonDropdown,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardSubtitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Label,
  Row,
  Button,
} from "reactstrap";
import { makeAdColumns } from "./TableColumns";
import * as _ from "lodash";
import { exportAdCSV, exportJSON } from "./export";
import { faDownload, faStar } from "@fortawesome/free-solid-svg-icons";
import {
  CompactMultiSelect,
  defaultColumnOptions,
  liftValue,
} from "../common/CustomSelect";
import AgeChart from "./AgeChart";
import GenderChart from "./GenderChart";
import { useDebounce } from "use-debounce";
import { patchAPI, postAPI } from "../../WebAPI";
import { useHistory } from "react-router-dom";
import UserContext from "../common/UserContext";
import WatchlistSettings from "../common/WatchlistSettings";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import ChartErrorBoundary from "../common/ChartErrorBoundary";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

const TIME_FILTERS = ["date_range_start", "date_range_end"];

const removeTime = (filters) =>
  _.pickBy(filters, (_, key) => !TIME_FILTERS.includes(key));

const tableColumns = makeAdColumns();

const columnOptions = _.sortBy(
  tableColumns.map((x) => liftValue(x.Header)),
  (x) => x.label
);

const makeQuery = (query) => ({
  geographies: query.geographies.map((x) => x.value),
  advertisers: query.advertisers.map((x) => x.value),
  ad_types: query.adTypes.map((x) => x.value),
  _raw: query,
});

const GoogleLayout = ({ savedSearch = {}, watchlist = null }) => {
  const { token } = useContext(UserContext);
  const history = useHistory();
  const [tableRows, setTableRows] = useState([]);
  const [filters, setFilters] = useState(
    watchlist ? normalizeFilters(watchlist.settings.filters) : []
  );
  const [debouncedRows] = useDebounce(tableRows, 150);
  const [, searchResults, onSearch, isSearching, searchError] = useSearch(
    "/api/google/search/"
  );
  const initialQuery = watchlist
    ? watchlist.settings.query._raw
    : savedSearch.query || blankQuery;
  const [query, setQuery] = useState(initialQuery);
  const [exportOpen, setExportOpen] = useState(false);
  const [activeColumns, setActiveColumns] = useState(
    defaultColumnOptions(tableColumns)
  );
  const [latestDate] = useLatestDate();

  useEffect(() => {
    if (savedSearch.created_at) {
      toastr.info(
        "Viewing Saved Results",
        `Viewing search results from ${moment(
          savedSearch.created_at
        )}. Click "Search" to view latest results`
      );
    }
  }, [savedSearch.created_at]);

  const watchlistData = (watchlist || {}).sample_data;
  const savedSearchData = useJSONL(savedSearch.result);

  const data = useMemo(
    () => searchResults || savedSearchData || watchlistData || [],
    [searchResults, savedSearchData, watchlistData]
  );

  const search = useCallback((query) => onSearch(makeQuery(query)), [onSearch]);

  useEffect(() => {
    setActiveColumns(defaultColumnOptions(tableColumns));
  }, []);

  const columns = useMemo(() => {
    return tableColumns.filter((c) =>
      activeColumns.some((x) => x.value === c.Header)
    );
  }, [activeColumns]);

  const createWatchlist = useCallback(async () => {
    const requestData = {
      name: "New Watchlist",
      service: "google-ads",
      settings: { query: makeQuery(query), filters: removeTime(filters) },
      // only store a partial slice for watchlists to save space
      sample_data: data.slice(0, Math.min(25, data.length)),
    };
    const watchlist = await postAPI("/api/watchlists/", requestData, { token });
    toastr.success(`Created new Google watchlist`);
    history.push(`/google/watchlists/${watchlist.id}/`);
  }, [history, query, filters, token, data]);

  const saveWatchlist = useCallback(
    async ({ name }) => {
      const requestData = {
        name,
        service: watchlist.service,
        settings: { query: makeQuery(query), filters: removeTime(filters) },
        // only store a partial slice for watchlists to save space
        sample_data: data.slice(0, Math.min(25, data.length)),
      };
      await patchAPI(`/api/watchlists/${watchlist.id}/`, requestData, {
        token,
      });
      toastr.success(`Updated Google watchlist ${name}`);
    },
    [query, filters, token, watchlist, data]
  );

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">
        {watchlist ? "Google Ad Watchlist" : "Google Ad Search"}
      </h1>

      {watchlist && (
        <WatchlistSettings watchlist={watchlist} onSave={saveWatchlist} />
      )}

      <Row>
        <Col lg="12">
          <Card>
            <CardHeader className="pb-1">
              <CardTitle tag="h5">Search Query</CardTitle>
              <h6 className="card-subtitle text-muted">
                Search by advertiser, target geography, and/or ad type
              </h6>
            </CardHeader>
            <CardBody className="pt-1">
              <SearchBar
                onSearch={search}
                onChange={setQuery}
                initialQuery={query}
                isSearching={isSearching}
              />
              <div className="mt-3">
                <span>
                  <FontAwesomeIcon icon={faCalendar} />{" "}
                  {latestDate
                    ? `Latest date available: ${latestDate}`
                    : `Latest date available: fetching...`}
                </span>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="col-6">
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Spend by Gender Targeting (USD)</CardTitle>
              <CardSubtitle>
                For each ad, spend is split across each gender target equally
                because Google does not report spend by target.
              </CardSubtitle>
            </CardHeader>
            <CardBody>
              <ChartErrorBoundary>
                <GenderChart data={debouncedRows} />
              </ChartErrorBoundary>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-6">
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Spend by Age Targeting (USD)</CardTitle>
              <CardSubtitle>
                For each ad, spend is split across each age target equally
                because Google does not report spend by target.
              </CardSubtitle>
            </CardHeader>
            <CardBody>
              <ChartErrorBoundary>
                <AgeChart data={debouncedRows} />
              </ChartErrorBoundary>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card>
            <CardHeader className="pb-1">
              <CardTitle tag="h5">Search Results</CardTitle>
            </CardHeader>
            <CardBody className="pt-0">
              <Row>
                <Col>
                  <Form className="form-inline">
                    <FormGroup className="ml-3">
                      <Label for="columns">Columns</Label>
                      <CompactMultiSelect
                        id="columns"
                        css={css`
                          width: 225px;
                          margin-left: 5px;
                        `}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={columnOptions}
                        value={activeColumns}
                        onChange={setActiveColumns}
                        isClearable={false}
                      />
                    </FormGroup>
                    <ButtonDropdown
                      className="ml-3 mr-3"
                      isOpen={exportOpen}
                      toggle={() => setExportOpen(!exportOpen)}
                    >
                      <DropdownToggle caret>
                        <FontAwesomeIcon icon={faDownload} />
                        &nbsp;Export
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => exportAdCSV(tableRows)}>
                          CSV
                        </DropdownItem>
                        <DropdownItem onClick={() => exportJSON(tableRows)}>
                          JSON
                        </DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                    {!watchlist && (
                      <Button onClick={createWatchlist}>
                        <FontAwesomeIcon icon={faStar} /> Save as Watchlist
                      </Button>
                    )}
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3">
                  {isSearching && (
                    <Loader
                      type="Grid"
                      color="#9d7bd8"
                      height={100}
                      width={100}
                    />
                  )}
                  {searchError && <SearchError {...searchError} />}
                  {!searchError && !isSearching && (
                    <div
                      css={css`
                        overflow-x: auto;
                      `}
                    >
                      <DetailTable
                        columns={columns}
                        data={data || []}
                        onChange={setTableRows}
                        onFilter={setFilters}
                        initialFilters={filters}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default GoogleLayout;
