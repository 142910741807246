import React, { useContext } from "react";
import useAsyncEffect from "use-async-effect";
import UserContext from "./UserContext";
import { getAPI } from "../../WebAPI";

const initialState = {
  data: undefined,
  error: undefined,
  isLoading: true,
  response: undefined,
};

const errorPage = ({ error }) => <div>An Error Occurred: {error}</div>;

const loadingPage = () => <div>Loading...</div>;

export const withFetch = (
  url,
  { renderError, renderLoad, propName } = { propName: "data" }
) => (Component) => {
  return (props) => {
    const [state, updateState] = React.useState(initialState);
    const { token } = useContext(UserContext);

    useAsyncEffect(
      async (isMounted) => {
        try {
          const data = await getAPI(url, token);
          if (!isMounted) return;
          updateState((x) => ({ ...x, data, isLoading: false }));
        } catch (response) {
          let error;
          try {
            const body = await response.json();
            error = body.detail ?? response.statusText ?? "Request error";
          } catch (e) {
            error = response.statusText ?? "Request error";
          }
          if (!isMounted) return;
          updateState((x) => ({ ...x, response, isLoading: false, error }));
        }
      },
      [token]
    );

    if (state.error) {
      return (renderError || errorPage)({ ...props, error: state.error });
    } else if (state.data) {
      return <Component {...props} {...{ [propName]: state.data }} />;
    } else {
      return (renderLoad || loadingPage)(props);
    }
  };
};
