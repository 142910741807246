/** @jsxImportSource @emotion/react */
import { useContext, useState, useCallback, useEffect } from "react";
import { css } from "@emotion/core";
import { Button, Col, Form, FormGroup, Label, Row } from "reactstrap";
import { getAPI } from "../../WebAPI";
import UserContext from "../common/UserContext";
import { CompactMultiSelect } from "../common/CustomSelect";
import { CompactAsyncMultiSelect } from "../common/CustomSelect";
import useAsyncEffect from "use-async-effect";

const AD_TYPE_OPTIONS = [
  { value: "Text", label: "Text" },
  { value: "Video", label: "Video" },
  { value: "Image", label: "Image" },
];

export const blankQuery = { advertisers: [], geographies: [], adTypes: [] };

export const useLatestDate = () => {
  const { token } = useContext(UserContext);
  const [date, setDate] = useState(null);
  useAsyncEffect(
    async (isMounted) => {
      if (!date) {
        try {
          const { date: latest } = await getAPI("/api/google/latest/", token);
          if (!isMounted()) return;
          setDate(latest);
        } catch (exc) {
          console.error("Error fetching latest date", exc);
        }
      }
    },
    [date, setDate]
  );
  return [date];
};

const SearchBar = ({
  isSearching,
  onSearch,
  onChange,
  initialQuery = blankQuery,
}) => {
  const { token } = useContext(UserContext);
  const [query, setQuery] = useState(initialQuery);

  useEffect(() => {
    if (onChange) {
      onChange(query);
    }
  }, [query, onChange]);

  const loadAdvertisers = useCallback(
    (query) => getAPI("/api/google/advertisers/", token, { q: query }),
    [token]
  );
  const loadGeographies = useCallback(
    (query) => getAPI("/api/google/geographies/", token, { q: query }),
    [token]
  );

  return (
    <Row>
      <Col lg="12">
        <Form inline>
          <FormGroup className="form-inline mr-2 mb-1">
            <Label for="advertiser-select">Advertiser</Label>
            <CompactAsyncMultiSelect
              css={css`
                width: 250px;
              `}
              id="advertiser-select"
              className="react-select-container ml-2"
              classNamePrefix="react-select"
              loadOptions={loadAdvertisers}
              onChange={(advertisers) =>
                setQuery((x) => ({ ...x, advertisers }))
              }
              defaultOptions={query.advertisers}
              value={query.advertisers}
              placeholder="All Advertisers"
            />
          </FormGroup>
          <FormGroup className="form-inline mr-2 mb-1">
            <Label for="geography-select">Geography</Label>
            <CompactAsyncMultiSelect
              css={css`
                width: 250px;
              `}
              id="advertiser-select"
              className="react-select-container ml-2"
              classNamePrefix="react-select"
              loadOptions={loadGeographies}
              onChange={(geographies) =>
                setQuery((x) => ({ ...x, geographies }))
              }
              defaultOptions={query.geographies}
              value={query.geographies}
              placeholder="All Geographic Targeting"
            />
          </FormGroup>
          <FormGroup className="form-inline mr-2 mb-1">
            <Label for="type-select">Ad Type</Label>
            <CompactMultiSelect
              css={css`
                width: 225px;
              `}
              id="type-select"
              className="react-select-container ml-2"
              classNamePrefix="react-select"
              isMulti
              onChange={(adTypes) => setQuery((x) => ({ ...x, adTypes }))}
              value={query.adTypes}
              options={AD_TYPE_OPTIONS}
              placeholder="All Ad Types"
            />
          </FormGroup>
          <Button
            color="secondary"
            className="mb-1"
            disabled={
              isSearching ||
              (!query.advertisers?.length &&
                !query.adTypes?.length &&
                !query.geographies?.length)
            }
            onClick={(event) => {
              event.preventDefault();
              onSearch(query);
            }}
          >
            Search
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

export default SearchBar;
