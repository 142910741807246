import React from "react";
import { Alert, Button, Col, Container, Input, Row } from "reactstrap";
import { Field, Form, Formik } from "formik";
import { postAPI } from "../WebAPI";

import "./Login.css";

function performLogin(onLogin) {
  return function (
    { username, password, register },
    { setSubmitting, setStatus }
  ) {
    const url = register ? "/api/users/register/" : "/api/login/";

    return postAPI(url, { username, password })
      .then(({ token, flags, ...profile }) => {
        onLogin(token, profile, flags);
      })
      .catch((responseError) => {
        if (responseError.response.status === 400) {
          setStatus({ non_field_errors: ["Invalid credentials provided"] });
        } else {
          setStatus({ non_field_errors: [responseError.response.statusText] });
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
}

const LoginForm = ({ onLogin, className }) => (
  <Formik
    initialValues={{ username: "", password: "" }}
    onSubmit={performLogin(onLogin)}
    render={({
      isSubmitting,
      handleSubmit,
      setFieldValue,
      errors,
      status = {},
    }) => (
      <section className={className}>
        <Container>
          <Row className="justify-content-center">
            <Col md={5} lg={4}>
              <h3>Sign In/Up</h3>
              <Form onSubmit={handleSubmit} className="login-form">
                {status.non_field_errors && (
                  <Alert color="danger">{status.non_field_errors}</Alert>
                )}
                <Field
                  name="username"
                  render={({ field }) => (
                    <Input
                      id="username"
                      type="text"
                      placeholder="Username"
                      autocomplete="username"
                      {...field}
                      invalid={errors.username}
                    />
                  )}
                />
                <Field
                  name="password"
                  render={({ field }) => (
                    <Input
                      type="password"
                      placeholder="Password"
                      {...field}
                      invalid={errors.password}
                      id="current-password"
                      autocomplete="current-password"
                    />
                  )}
                />
                <div className="d-flex">
                  <Button
                    outline
                    color="primary"
                    className="btn-block py-2 mr-3"
                    disabled={isSubmitting}
                    name="login"
                    type="submit"
                    value="login"
                    onClick={() => {
                      setFieldValue("register", 0, false);
                      handleSubmit();
                    }}
                  >
                    Sign In!
                  </Button>
                  <Button
                    color="primary"
                    disabled={true}
                    name="register"
                    type="submit"
                    value="register"
                    className="btn-block py-2 mt-0"
                    onClick={() => {
                      setFieldValue("register", 1, false);
                      handleSubmit();
                    }}
                  >
                    Sign Up!
                  </Button>
                </div>
                <div className="text-center">
                  <p
                    className="text-secondary text-muted mt-3"
                    data-config-id="terms"
                  >
                    By signing in you agree with the Terms and Conditions and
                    Privacy Policy.
                  </p>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    )}
  />
);

export default LoginForm;
