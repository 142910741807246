import React from "react";

export const initialContext = {
  isStaff: false,
  token: undefined,
  username: undefined,
  facebookToken: undefined,
  facebookTokenRequired: true,
  flags: [],
};

const UserContext = React.createContext(initialContext);

export default UserContext;
