import { parse } from "json2csv";
import pako from "pako";
import download from "downloadjs";
import * as _ from "lodash";

export function exportJSON(data) {
  const content = JSON.stringify(data, null, 2);
  download(new Blob([content]), "facebook-ads.json", "application/json");
}

export function exportJSONL(data) {
  const content = pako.gzip(
    data.map((x) => JSON.stringify(x, null, 0)).join("\n")
  );
  download(
    new Blob([content]),
    "facebook-ads.jsonl.gz",
    "application/x-ndjson+gzip"
  );
}

function cleanTargeting(raw) {
  return _.sortBy(Object.values(raw), (x) => -x.spend_lower_bound).map((x) => ({
    ...x,
    spend_lower_bound: Math.round(x.spend_lower_bound),
    spend_upper_bound: !isNaN(x.spend_upper_bound)
      ? Math.round(x.spend_upper_bound)
      : null,
    impressions_lower_bound: Math.round(x.impressions_lower_bound),
    impressions_upper_bound: !isNaN(x.impressions_upper_bound)
      ? Math.round(x.impressions_upper_bound)
      : null,
  }));
}

export function exportTargetingCSV(data) {
  const result = {};

  for (const record of data) {
    for (const {
      region,
      percentage: regionPercentage,
    } of record.region_distribution) {
      for (const {
        age,
        gender,
        percentage: demoPercentage,
      } of record.demographic_distribution) {
        const key = `${age}x${gender}x${region}`;
        if (result[key] == null) {
          result[key] = {
            region,
            age,
            gender,
            spend_lower_bound: 0,
            spend_upper_bound: 0,
            impressions_lower_bound: 0,
            impressions_upper_bound: 0,
          };
        }
        result[key].spend_lower_bound +=
          parseInt(record.spend.lower_bound) *
          regionPercentage *
          demoPercentage;
        result[key].spend_upper_bound +=
          parseInt(record.spend.upper_bound) *
          regionPercentage *
          demoPercentage;
        result[key].impressions_lower_bound +=
          parseInt(record.impressions.lower_bound) *
          regionPercentage *
          demoPercentage;
        result[key].impressions_upper_bound +=
          parseInt(record.impressions.upper_bound) *
          regionPercentage *
          demoPercentage;
      }
    }
  }

  const content = parse(cleanTargeting(result), {
    // excelStrings: true,
    withBOM: true,
    fields: [
      { label: "Gender", value: "gender" },
      { label: "Region", value: "region" },
      { label: "Age", value: "age" },
      { label: "Spend Lower Bound", value: "spend_lower_bound" },
      { label: "Spend Upper Bound", value: "spend_upper_bound" },
      { label: "Impressions Lower Bound", value: "impressions_lower_bound" },
      { label: "Impressions Upper Bound", value: "impressions_upper_bound" },
    ],
  });
  download(new Blob([content]), "facebook-ads-targeting.csv", "text/csv");
}

export function exportDemographicsCSV(data) {
  const result = {};

  for (const record of data) {
    for (const { percentage, age, gender } of record.demographic_distribution) {
      const key = `${age}x${gender}`;
      if (result[key] == null) {
        result[key] = {
          age,
          gender,
          spend_lower_bound: 0,
          spend_upper_bound: 0,
          impressions_lower_bound: 0,
          impressions_upper_bound: 0,
        };
      }
      result[key].spend_lower_bound +=
        parseInt(record.spend.lower_bound) * percentage;
      result[key].spend_upper_bound +=
        parseInt(record.spend.upper_bound) * percentage;
      result[key].impressions_lower_bound +=
        parseInt(record.impressions.lower_bound) * percentage;
      result[key].impressions_upper_bound +=
        parseInt(record.impressions.upper_bound) * percentage;
    }
  }

  const content = parse(cleanTargeting(result), {
    // excelStrings: true,
    withBOM: true,
    fields: [
      { label: "Gender", value: "gender" },
      { label: "Age", value: "age" },
      { label: "Spend Lower Bound", value: "spend_lower_bound" },
      { label: "Spend Upper Bound", value: "spend_upper_bound" },
      { label: "Impressions Lower Bound", value: "impressions_lower_bound" },
      { label: "Impressions Upper Bound", value: "impressions_upper_bound" },
    ],
  });
  download(new Blob([content]), "facebook-ads-demographics.csv", "text/csv");
}

export function exportGeoCSV(data) {
  const result = {};

  for (const record of data) {
    for (const { region, percentage } of record.region_distribution) {
      if (result[region] == null) {
        result[region] = {
          region,
          spend_lower_bound: 0,
          spend_upper_bound: 0,
          impressions_lower_bound: 0,
          impressions_upper_bound: 0,
        };
      }
      result[region].spend_lower_bound +=
        parseInt(record.spend.lower_bound) * percentage;
      result[region].spend_upper_bound +=
        parseInt(record.spend.upper_bound) * percentage;
      result[region].impressions_lower_bound +=
        parseInt(record.impressions.lower_bound) * percentage;
      result[region].impressions_upper_bound +=
        parseInt(record.impressions.upper_bound) * percentage;
    }
  }

  const content = parse(cleanTargeting(result), {
    // excelStrings: true,
    withBOM: true,
    fields: [
      { label: "Region", value: "region" },
      { label: "Spend Lower Bound", value: "spend_lower_bound" },
      { label: "Spend Upper Bound", value: "spend_upper_bound" },
      { label: "Impressions Lower Bound", value: "impressions_lower_bound" },
      { label: "Impressions Upper Bound", value: "impressions_upper_bound" },
    ],
  });
  download(new Blob([content]), "facebook-ads-region.csv", "text/csv");
}

export function exportCSV(data) {
  const flat = data.map((x) => ({
    ...x,
    spend_lower_bound: (x.spend || {}).lower_bound,
    spend_upper_bound: (x.spend || {}).upper_bound,
    impressions_lower_bound: (x.impressions || {}).lower_bound,
    impressions_upper_bound: (x.impressions || {}).upper_bound,
    publisher_platforms: (x.publisher_platforms || []).join(", "),
  }));

  const content = parse(flat, {
    // excelStrings: true,
    withBOM: true,
    fields: [
      { label: "Created", value: "ad_creation_time" },
      { label: "Publisher Platforms", value: "publisher_platforms" },
      { label: "Page ID", value: "page_id" },
      { label: "Page Name", value: "page_name" },
      { label: "Snapshot URL", value: "ad_snapshot_url" },
      { label: "Creative Body", value: "ad_creative_body" },
      { label: "Spend Lower Bound", value: "spend_lower_bound" },
      { label: "Spend Upper Bound", value: "spend_upper_bound" },
      { label: "Impressions Lower Bound", value: "impressions_lower_bound" },
      { label: "Impressions Upper Bound", value: "impressions_upper_bound" },
      { label: "Funding Entity", value: "funding_entity" },
    ],
  });
  download(new Blob([content]), "facebook-ads.csv", "text/csv");
}

export function chartTable(chart, headerFields, data) {
  const flat = data.map((x) => ({
    ...x,
    spend_lower_bound: x.spend[0],
    spend_upper_bound: x.spend[1],
    impressions_lower_bound: x.impressions[0],
    impressions_upper_bound: x.impressions[1],
  }));

  const content = parse(flat, {
    // excelStrings: true,
    withBOM: true,
    fields: [
      ...headerFields,
      { label: "# Ads", value: "numAds" },
      { label: "Spend Lower Bound", value: "spend_lower_bound" },
      { label: "Spend Upper Bound", value: "spend_upper_bound" },
      { label: "Impressions Lower Bound", value: "impressions_lower_bound" },
      { label: "Impressions Upper Bound", value: "impressions_upper_bound" },
    ],
  });
  download(new Blob([content]), `facebook-${chart}.csv`, "text/csv");
}
