import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import Rollbar from "rollbar";

import "./theme/css/modern.css";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

if (process.env.NODE_ENV !== "production") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React);
}

ReactDOM.render(<App />, document.getElementById("root"));

if (process.env.REACT_APP_ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN) {
  window.rollbar = Rollbar.init({
    accessToken: process.env.REACT_APP_ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
      client: {
        javascript: {
          code_version: process.env.REACT_APP_HEROKU_SLUG_COMMIT,
        },
      },
    },
  });
}
