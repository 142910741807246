/** @jsxImportSource @emotion/react */
import { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Input,
  Form,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import { css } from "@emotion/core";

const WatchlistSettings = ({ watchlist, onSave }) => {
  const [name, setName] = useState(watchlist.name);

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardHeader className="pb-1">
            <CardTitle tag="h5">Watchlist Settings</CardTitle>
          </CardHeader>
          <CardBody className="pt-1">
            <Form inline>
              <FormGroup className="mr-2">
                <Label for="watchlist-name" className="mr-2">
                  Name
                </Label>
                <Input
                  css={css`
                    min-width: 300px;
                  `}
                  onChange={(e) => setName(e.target.value)}
                  id="watchlist-name"
                  name="name"
                  value={name}
                />
              </FormGroup>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  onSave({ name });
                }}
              >
                Save Watchlist
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default WatchlistSettings;
