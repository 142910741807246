import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebook } from "@fortawesome/free-brands-svg-icons";
import {faHistory, faList, faColumns} from '@fortawesome/free-solid-svg-icons';

const recentSearches = {
  path: "/history/",
  name: "Search History",
  icon: () => <FontAwesomeIcon icon={faHistory} />,
};

const watchlists = {
  path: "/watchlists/",
  name: "Watchlists",
  icon: () => <FontAwesomeIcon icon={faList} />,
};

const entityLists = {
  path: "/known/",
  name: "Known Entities",
  icon: () => <FontAwesomeIcon icon={faColumns} />,
  flag: "known-entities"
};

const facebookRoutes = {
  path: "/facebook/",
  name: "Facebook",
  icon: () => <FontAwesomeIcon icon={faFacebook} />,
  children: null
};

// const googleRoutes = {
//   path: "/google/",
//   name: "Google",
//   icon: () => <FontAwesomeIcon icon={faGoogle} />,
//   children: null
// };



// Dashboard specific routes
export const dashboard = [facebookRoutes];

// All routes
export default [watchlists, entityLists, recentSearches, facebookRoutes];
