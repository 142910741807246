
				var addMethods = require("../../../.yarn/__virtual__/workerize-loader-virtual-37c13cde44/0/cache/workerize-loader-npm-2.0.2-6ea88ec3ea-0337cb2085.zip/node_modules/workerize-loader/dist/rpc-wrapper.js")
				var methods = ["inflateSearchResults"]
				module.exports = function() {
					var w = new Worker(__webpack_public_path__ + "a23e22d9e5088376779a.worker.js", { name: "[fullhash].worker.js" })
					URL.revokeObjectURL(__webpack_public_path__ + "a23e22d9e5088376779a.worker.js");
					addMethods(w, methods)
					
					return w
				}
			