import React, { useEffect, useMemo } from "react";
import Datamap from "datamaps/dist/datamaps.usa.min.js";
import * as d3 from "d3";
import Humanize from "humanize-plus";

const ChoroplethMap = ({
  elementId = "choropleth_map",
  data = [],
  onSelect,
}) => {
  const scale = useMemo(() => {
    const [minValue, maxValue] = d3.extent(data.map((x) => x[1]));
    return d3
      .scaleLinear()
      .domain([minValue, maxValue])
      .range(["#EFEFFF", "#02386F"]); // blue color
  }, [data]);

  const dataset = {};
  for (const [geo, value, spend] of data) {
    dataset[geo] = { value, spend, fillColor: scale(value) };
  }

  useEffect(() => {
    // FIXME: remove the old SVG!

    const svgs = document.getElementById(elementId).getElementsByTagName("svg");
    for (const svg of svgs) {
      svg.remove();
    }

    new Datamap({
      element: document.getElementById(elementId),
      // countries not listed in dataset will be painted with this color
      fills: {
        defaultFill: "#F5F5F5",
      },
      scope: "usa",
      data: dataset,
      responsive: true,
      geographyConfig: {
        borderColor: "#DEDEDE",
        highlightBorderWidth: 2,
        // dataJson: US.objects.states,
        // don't change color on mouse hover
        highlightFillColor: function (geo) {
          return geo["fillColor"] || "#F5F5F5";
        },
        // only change border
        highlightBorderColor: "#B7B7B7",
        // show desired information in tooltip
        popupTemplate: function (geo, data) {
          // don't show tooltip if country don't present in dataset
          if (!data) {
            return;
          }
          // tooltip content
          return [
            '<div class="hoverinfo">',
            "<strong>",
            geo.properties.name,
            "</strong>",
            "<br>Impressions (est.): <strong>",
            Humanize.intComma(Math.round(data.value)),
            "</strong>",
            "<br>Spend (est.): <strong>",
            Humanize.intComma(Math.round(data.spend)),
            "</strong>",
            "</div>",
          ].join("");
        },
      },
      done: function (datamap) {
        // https://github.com/markmarkoh/datamaps/blob/master/README.md#getting-started
        datamap.svg
          .selectAll(".datamaps-subunit")
          // .on({
          //   mouseover: function (d) {
          //     d3.select(this).style("cursor", "pointer");
          //   },
          //   mouseout: function (d) {
          //     d3.select(this).style("cursor", "default");
          //   },
          // })
          .on("click", function (geography) {
            onSelect(geography.properties.name);
          });
      },
    });
  }, [elementId, dataset, onSelect]);

  return <div id={elementId} />;
};

export default ChoroplethMap;
