/** @jsxImportSource @emotion/react */
import { useMemo, useContext, useState } from "react";
import { css } from "@emotion/core";
import { Card, Col, Container, Row, Table, Button } from "reactstrap";
import { withFetch } from "./FetchHOC";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import * as _ from "lodash";
import { Link } from "react-router-dom";
import { deleteAPI } from "../../WebAPI";
import UserContext from "./UserContext";
import { toastr } from "react-redux-toastr";

const HistoryTableLayout = (props) => (
  <Table>
    <thead>
      <tr>
        <th>Service</th>
        <th>Date</th>
        <th>Query</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>{props.children}</tbody>
  </Table>
);

const renderValue = (value) => {
  if (Array.isArray(value)) {
    return value.map((x) => renderValue(x)).join(", ");
  } else if (typeof value === "object") {
    return value.label;
  } else {
    return value.toString();
  }
};

const HistoryTable = ({ searches, onDelete, deleted }) => (
  <HistoryTableLayout>
    {!searches.length && (
      <tr>
        <td colSpan="4">No recent searches.</td>
      </tr>
    )}
    {searches
      .filter((search) => !deleted.includes(search.id))
      .map((search) => (
        <tr key={search.id}>
          <td>
            <FontAwesomeIcon
              icon={search.service === "google-ads" ? faGoogle : faFacebook}
            />
          </td>
          <td>{moment(search.created_at).fromNow()}</td>
          <td>
            <ul
              css={css`
                margin-bottom: 0;
                padding-left: 20px;
              `}
            >
              {Object.entries(search.query).map(([attr, value]) => {
                if (
                  (Array.isArray(value) && value.length) ||
                  (!Array.isArray(value) && value)
                ) {
                  return (
                    <li key={attr}>
                      {_.startCase(_.camelCase(attr))}: {renderValue(value)}
                    </li>
                  );
                } else {
                  return null;
                }
              })}
            </ul>
          </td>
          <td>
            <Button
              color="info"
              className="mr-2"
              to={`/${
                search.service === "google-ads" ? "google" : "facebook"
              }/${search.id}/`}
              tag={Link}
            >
              View
            </Button>

            <Button color="danger" onClick={() => onDelete(search.id)}>
              Delete
            </Button>
          </td>
        </tr>
      ))}
  </HistoryTableLayout>
);

const LoadingTable = () => (
  <HistoryTableLayout>
    <tr>
      <td colSpan={4}>Loading records</td>
    </tr>
  </HistoryTableLayout>
);

export const SearchHistory = () => {
  const [deleted, setDeleted] = useState([]);
  const { token } = useContext(UserContext);

  const FetchedTable = useMemo(
    () =>
      withFetch("/api/search/recent/", {
        propName: "searches",
        renderLoad: () => <LoadingTable />,
      })(HistoryTable),
    []
  );

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Search History</h1>

      <Row>
        <Col lg="12">
          <Card>
            <FetchedTable
              deleted={deleted}
              onDelete={(id) => {
                deleteAPI(`/api/search/recent/${id}/`, token)
                  .then(() => toastr.success("Deleted Saved Search"))
                  .catch(() => {
                    toastr.warning(
                      "Error",
                      "An error occurred while trying to delete the search."
                    );
                    setDeleted((xs) => xs.filter((x) => x !== id));
                  });
                setDeleted((xs) => [...xs, id]);
              }}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
