import React, { useState } from "react";
import "./App.css";
import FacebookLayout from "./facebook/Facebook";
import GoogleLayout from "./google/Google";
import { Provider } from "react-redux";
import LoginForm from "./Login";
import UserContext, {
  initialContext as initialUserContext,
} from "./common/UserContext";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { withFetch } from "./common/FetchHOC";
import DashboardLayout from "../theme/layouts/Dashboard";
import ReduxToastr from "react-redux-toastr";
import Page404 from "../theme/pages/auth/Page404";
import ErrorBoundary from "./common/ErrorBoundary";
import { getAPI } from "../WebAPI";
import store from "../theme/redux/store/index";
import { SearchHistory } from "./common/SearchHistory";
import { WatchlistView } from "./common/Watchlists";
import { KnownEntitiesListListView } from "./knownEntities/KnownEntities";
import FlagContext from "./common/FlagContext";
import useAsyncEffect from "use-async-effect";
import KnownEntitiesDetail from "./knownEntities/KnownEntitiesDetail";

const savedAuth = window.sessionStorage.getItem("auth");

const initialState = savedAuth ? JSON.parse(savedAuth) : initialUserContext;

function App() {
  const [authState, updateAuth] = useState(initialState);
  const [flags, setFlags] = useState([]);

  useAsyncEffect(async () => {
    const { flags } = await getAPI("/api/flags/", authState.token);
    setFlags(flags);
  }, [authState, setFlags]);

  const saveAuth = (state) => {
    updateAuth(state);
    window.sessionStorage.setItem("auth", JSON.stringify(state));
  };

  return (
    <Provider store={store}>
      <UserContext.Provider value={{ ...authState, updateAuth: saveAuth }}>
        <FlagContext.Provider value={{ flags }}>
          <Router>
            {authState.token && (
              <DashboardLayout>
                <ErrorBoundary>
                  <Switch>
                    <Route
                      path="/google/watchlists/:watchlist/"
                      render={({ match }) => {
                        const url = `/api/watchlists/${match.params.watchlist}/?service=google-ads`;
                        const Layout = withFetch(url, {
                          propName: "watchlist",
                        })(GoogleLayout);
                        return <Layout />;
                      }}
                    />
                    <Route
                      path="/facebook/watchlists/:watchlist/"
                      render={({ match }) => {
                        const url = `/api/watchlists/${match.params.watchlist}/?service=facebook-ads`;
                        const Layout = withFetch(url, {
                          propName: "watchlist",
                        })(FacebookLayout);
                        return <Layout />;
                      }}
                    />
                    <Route
                      exact
                      path="/watchlists/"
                      component={WatchlistView}
                    />
                    <Route
                      path="/known/:entityList/"
                      render={({ match }) => {
                        const url = `/api/known/${match.params.entityList}/`;
                        const Layout = withFetch(url, {
                          propName: "entityList",
                        })(KnownEntitiesDetail);
                        return <Layout />;
                      }}
                    />
                    <Route
                      exact
                      path="/known/"
                      component={KnownEntitiesListListView}
                    />
                    <Route exact path="/history/" component={SearchHistory} />
                    <Route
                      exact
                      path="/facebook/:search/"
                      render={({ match }) => {
                        const url = `/api/search/recent/${match.params.search}/`;
                        const Layout = withFetch(url, {
                          propName: "savedSearch",
                        })(FacebookLayout);
                        return <Layout />;
                      }}
                    />
                    <Route exact path="/facebook/" component={FacebookLayout} />
                    <Route
                      exact
                      path="/google/:search/"
                      render={({ match }) => {
                        const url = `/api/search/recent/${match.params.search}/`;
                        const Layout = withFetch(url, {
                          propName: "savedSearch",
                        })(GoogleLayout);
                        return <Layout />;
                      }}
                    />
                    <Route exact path="/google/" component={GoogleLayout} />
                    <Route exact path="/" component={SearchHistory} />
                    <Route path="/" component={Page404} />
                  </Switch>
                </ErrorBoundary>
              </DashboardLayout>
            )}
            {!authState.token && (
              <LoginForm
                className="mt-5"
                onLogin={(token, meta = {}, flags) => {
                  const auth = { ...authState, ...meta, token, flags };
                  saveAuth(auth);
                }}
              />
            )}
          </Router>
        </FlagContext.Provider>
      </UserContext.Provider>
      <ReduxToastr
        timeOut={5000}
        newestOnTop={true}
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </Provider>
  );
}

export default App;
