/** @jsxImportSource @emotion/react */
import React, { useContext, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Input,
  Form,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import { css } from "@emotion/core";
import UserContext from "../common/UserContext";
import Switch from "react-switch";

const KnownEntitySettingsCard = ({ entityList, onSave }) => {
  const [name, setName] = useState(entityList.name);
  const [universal, setUniversal] = useState(entityList.universal);
  const { isStaff } = useContext(UserContext);

  return (
    <Card>
      <CardHeader className="pb-1">
        <CardTitle tag="h5">Known Entity List Settings</CardTitle>
      </CardHeader>
      <CardBody className="pt-1">
        <Form inline>
          <FormGroup className="mr-2">
            <Label for="watchlist-name" className="mr-2">
              Name
            </Label>
            <Input
              css={css`
                min-width: 300px;
              `}
              onChange={(e) => setName(e.target.value)}
              id="watchlist-name"
              name="name"
              value={name}
            />
          </FormGroup>

          {isStaff && (
            <React.Fragment>
              <Label htmlFor="universal-switch" className="mr-2">
                Universal
              </Label>
              <Switch
                id="universal-switch"
                disabled={!isStaff}
                onChange={setUniversal}
                checked={universal}
                height={20}
                width={40}
              />
            </React.Fragment>
          )}

          {entityList.can_edit && (
            <Button
              className="ml-2"
              onClick={(e) => {
                e.preventDefault();
                onSave({ name, universal });
              }}
            >
              Save Known Entity List
            </Button>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default KnownEntitySettingsCard;
