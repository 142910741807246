/** @jsxImportSource @emotion/react */
import { useMemo, useContext, useState, useCallback } from "react";
import { css } from "@emotion/core";
import {
  Card,
  Col,
  Container,
  Row,
  Table,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  Input,
} from "reactstrap";
import { withFetch } from "../common/FetchHOC";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { deleteAPI, postAPI } from "../../WebAPI";
import UserContext from "../common/UserContext";
import { toastr } from "react-redux-toastr";
import { faEyeSlash, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

const KnownEntityListTableLayout = ({ children }) => (
  <Table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Visibility</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </Table>
);

const ConfirmDeleteModal = ({ name, onDelete, children }) => {
  const [showModal, setModal] = useState(false);
  const [text, setText] = useState("");

  const toggle = () => setModal(!showModal);

  return (
    <div>
      {children(toggle)}
      <Modal isOpen={showModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Delete Known Entities List</ModalHeader>
        <ModalBody>
          Type the name of the known entity list to confirm delete:{" "}
          <code>{name}</code>
          <Input
            className="mt-2"
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            disabled={text.toLowerCase() !== name.toLowerCase()}
            onClick={onDelete}
          >
            Delete
          </Button>{" "}
          <Button color="link" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const KnownEntityListTable = ({ knownEntityLists, onDelete, deleted }) => (
  <KnownEntityListTableLayout>
    {!knownEntityLists.length && (
      <tr>
        <td colSpan="3">No known entity lists</td>
      </tr>
    )}
    {knownEntityLists
      .filter((x) => !deleted.includes(x.id))
      .map((x) => (
        <tr key={x.id}>
          <td>
            <Link to={`/known/${x.id}`}>{x.name}</Link>
          </td>
          <td>
            {x.universal ? (
              <span>
                <FontAwesomeIcon icon={faGlobe} /> Universal
              </span>
            ) : (
              <span>
                <FontAwesomeIcon icon={faEyeSlash} /> Private
              </span>
            )}
          </td>
          <td>
            {x.can_edit && (
              <div
                css={css`
                  display: flex;
                `}
              >
                <Button
                  color="info"
                  className="mr-2"
                  to={`/known/${x.id}`}
                  tag={Link}
                >
                  Edit
                </Button>

                <ConfirmDeleteModal name={x.name} onDelete={() => onDelete(x)}>
                  {(toggle) => (
                    <Button color="danger" onClick={toggle}>
                      Delete
                    </Button>
                  )}
                </ConfirmDeleteModal>
              </div>
            )}
          </td>
        </tr>
      ))}
  </KnownEntityListTableLayout>
);

const LoadingTable = () => (
  <KnownEntityListTableLayout>
    <tr>
      <td colSpan={3}>Loading known entities lists...</td>
    </tr>
  </KnownEntityListTableLayout>
);

export const KnownEntitiesListListView = () => {
  const [deleted, setDeleted] = useState([]);
  const { token } = useContext(UserContext);
  const history = useHistory();

  const FetchedTable = useMemo(
    () =>
      withFetch("/api/known/", {
        propName: "knownEntityLists",
        renderLoad: () => <LoadingTable />,
      })(KnownEntityListTable),
    []
  );

  const createList = useCallback(async () => {
    try {
      const { id } = await postAPI(
        `/api/known/`,
        { name: "New List", facebook_pages: [], facebook_entities: [] },
        { token }
      );
      history.push(`/known/${id}/`);
    } catch (ex) {
      toastr.error("Error", `Error creating new entity list: ${ex}`);
    }
  }, [token, history]);

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Known Entity Lists</h1>

      <Button className="mb-3" onClick={createList}>
        Create List
      </Button>

      <Row>
        <Col lg="12">
          <Card>
            <FetchedTable
              deleted={deleted}
              onDelete={({ id, name }) => {
                deleteAPI(`/api/known/${id}/`, token)
                  .then(() =>
                    toastr.success(
                      "Deleted Known Entities List",
                      `Deleted known entities list ${name}`
                    )
                  )
                  .catch(() => {
                    setDeleted((xs) => xs.filter((x) => x !== id));
                    toastr.warning(
                      "Error",
                      `An error occurred while trying to known entities list ${name}.`
                    );
                  });
                setDeleted((xs) => [...xs, id]);
              }}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
