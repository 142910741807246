/** @jsxImportSource @emotion/react */
import React, { useMemo, useState } from "react";
import * as _ from "lodash";
import {
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import { css } from "@emotion/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faFilter } from "@fortawesome/free-solid-svg-icons";
import URI from "urijs";

const PageLink = ({ pageId, name }) => (
  <a
    href={`https://www.facebook.com/${pageId}/`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {name} <FontAwesomeIcon icon={faExternalLinkAlt} />
  </a>
);

const CampaignLink = ({ campaignId, label, facebookToken }) => {
  const uri = new URI("https://www.facebook.com/ads/archive/render_ad/")
    .search({ id: campaignId, access_token: facebookToken })
    .toString();
  return (
    <a href={uri} target="_blank" rel="noopener noreferrer">
      {label} <FontAwesomeIcon icon={faExternalLinkAlt} />
    </a>
  );
};

export const FundingEntityCoordinationModal = ({
  pageId,
  className,
  coordination,
  pageNames,
  children,
  setColumnFilter,
}) => {
  const [isOpen, toggle] = useState(false);

  const pageCollaboration = {};
  for (const { page_ids, score } of coordination) {
    const otherId = page_ids.filter((x) => x !== pageId)[0];
    pageCollaboration[otherId] = _.flatten(score.evidence);
  }

  return (
    <React.Fragment>
      {children(toggle)}
      <Modal
        size="lg"
        isOpen={isOpen}
        toggle={() => toggle(false)}
        className={className}
        scrollable
      >
        <ModalHeader toggle={() => toggle(false)}>
          Funding Entity Coordination
        </ModalHeader>
        <ModalBody>
          <Table striped>
            <thead>
              <tr>
                <th>Page</th>
                <th>Funding Entities in Common</th>
              </tr>
            </thead>
            <tbody>
              {_.sortBy(
                Object.entries(pageCollaboration),
                ([otherId, _]) => pageNames[otherId]
              ).map(([otherId, entities]) => (
                <tr key={otherId}>
                  <td>
                    <b>
                      <PageLink pageId={otherId} name={pageNames[otherId]} />
                    </b>
                  </td>
                  <td>
                    {entities.map((entity) => (
                      <a
                        href="#"
                        key={entity}
                        onClick={(e) => {
                          e.preventDefault();
                          setColumnFilter("funding_entity", [entity]);
                        }}
                      >
                        {entity}
                        <FontAwesomeIcon icon={faFilter} />
                      </a>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            onClick={() => {
              setColumnFilter("page_id", [
                ...Object.keys(pageCollaboration),
                pageId,
              ]);
              toggle(false);
            }}
          >
            <FontAwesomeIcon icon={faFilter} /> Set Page Filter
          </Button>
          <Button color="link" onClick={() => toggle(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

function makeText({ method, evidence, score, facebookToken, setColumnFilter }) {
  switch (method) {
    case "funding-entity":
      return [
        <li key={method}>
          Funding entity:{" "}
          {evidence.map((entity) => (
            <a
              href="#"
              key={entity}
              onClick={(e) => {
                e.preventDefault();
                // entity is already an array b/c of how we store the evidence
                setColumnFilter("funding_entity", entity);
              }}
            >
              {entity}
              <FontAwesomeIcon icon={faFilter} />
            </a>
          ))}
        </li>,
      ];
    case "creation-time-10min":
      return [
        <li key={method}>
          Ad creation time (similarity={Math.round(score * 100)}%)
        </li>,
      ];
    case "hashtags":
      return [
        <li key={method}>
          Hashtags:{" "}
          {_.uniq(_.flatten(evidence)).map((hashtag) => (
            <a
              className="mr-1"
              href="#"
              key={hashtag}
              onClick={(e) => {
                e.preventDefault();
                setColumnFilter("ad_creative_body", hashtag);
              }}
            >
              {hashtag}
              <FontAwesomeIcon icon={faFilter} />
            </a>
          ))}
        </li>,
      ];
    case "urls":
      return [
        <li key={method}>
          URLs:{" "}
          {_.uniq(_.flatten(evidence)).map((url) => (
            <a
              className="mr-1"
              href="#"
              key={url}
              onClick={(e) => {
                e.preventDefault();
                setColumnFilter("ad_creative_body", url);
              }}
            >
              {url}
              <FontAwesomeIcon icon={faFilter} />
            </a>
          ))}
        </li>,
      ];
    case "ad-creative-body":
      return _.uniqBy(evidence, (x) => x.tokens).map(
        ({ tokens, campaigns }, index) => (
          <li key={`${method}-${index}`}>
            Creative: {tokens}
            <ul>
              <li>
                <CampaignLink
                  facebookToken={facebookToken}
                  campaignId={campaigns[0]}
                  label="Example #1"
                />
              </li>
              <li>
                <CampaignLink
                  facebookToken={facebookToken}
                  campaignId={campaigns[1]}
                  label="Example #2"
                />
              </li>
            </ul>
          </li>
        )
      );
    default:
      return [];
  }
}

export const RiskGauge = ({ risk }) => {
  return `${Math.round(risk * 100)}%`;
};

export const CoordinationModal = ({
  pageId,
  className,
  coordination,
  pageNames,
  children,
  setColumnFilter,
  facebookToken,
}) => {
  const [isOpen, toggle] = useState(false);

  const pageCollaboration = {};

  for (const [method, methodCoordination] of Object.entries(coordination)) {
    for (const { page_ids, score } of methodCoordination) {
      const otherId = page_ids.filter((x) => x !== pageId)[0];
      if (!pageCollaboration[otherId]) {
        pageCollaboration[otherId] = [];
      }
      pageCollaboration[otherId].push({
        method,
        evidence: score.evidence,
        score: score.score,
      });
    }
  }

  const results = _.sortBy(
    Object.entries(pageCollaboration),
    ([_, evidence]) => -Math.max(...evidence.map((x) => x.score)),
    ([otherId, _]) => pageNames[otherId]
  );

  return (
    <React.Fragment>
      {children(toggle)}
      <Modal
        size="lg"
        isOpen={isOpen}
        toggle={() => toggle(false)}
        className={className}
        scrollable
      >
        <ModalHeader toggle={() => toggle(false)}>
          Page Coordination
        </ModalHeader>
        <ModalBody>
          <Table striped>
            <thead>
              <tr>
                <th>Page</th>
                <th>Score</th>
                <th>Evidence</th>
              </tr>
            </thead>
            <tbody>
              {results.map(([otherId, evidence]) => {
                const bullets = _.sortBy(
                  evidence,
                  (x) => -x.score
                ).flatMap((x) =>
                  makeText({ ...x, facebookToken, setColumnFilter })
                );
                return (
                  <tr key={otherId}>
                    <td>
                      <b>
                        <PageLink pageId={otherId} name={pageNames[otherId]} />
                      </b>
                    </td>
                    <td>
                      <RiskGauge
                        risk={Math.max(...evidence.map((x) => x.score))}
                      />
                    </td>
                    <td>
                      {bullets.length > 1 ? (
                        <ul
                          css={css`
                            padding-left: 15px;
                          `}
                        >
                          {bullets}
                        </ul>
                      ) : (
                        bullets[0]
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            onClick={() => {
              setColumnFilter("page_id", [
                ...Object.keys(pageCollaboration),
                pageId,
              ]);
              toggle(false);
            }}
          >
            <FontAwesomeIcon icon={faFilter} /> Set Page Filter
          </Button>
          <Button color="link" onClick={() => toggle(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export function anyCoordination(pageId, coordination, minCoordinate) {
  if (!coordination) {
    return false;
  }

  const fundingEntity = (coordination["funding-entity"] ?? [])
    .flatMap((x) => x.page_ids)
    .some((x) => x !== pageId);

  if (fundingEntity) {
    return true;
  }

  const scorers = Object.keys(coordination).filter(
    (x) => x !== "funding-entity"
  );
  return scorers.some((scorer) =>
    coordination[scorer].some((x) => x.score.score >= minCoordinate / 100)
  );
}

export const CoordinationMarkers = ({
  pageId,
  coordination,
  pageNames,
  minCoordinate,
  setColumnFilter,
  facebookToken,
}) => {
  const fundingEntities = useMemo(() => {
    return _.uniq(
      ((coordination || {})["funding-entity"] || []).flatMap((x) => x.page_ids)
    )
      .filter((x) => x !== pageId)
      .map((x) => pageNames[x]);
  }, [coordination, pageNames, pageId]);

  const hiddenCoordination = useMemo(() => {
    const scorers = Object.keys(coordination || {}).filter(
      (x) => x !== "funding-entity"
    );
    return _.uniq(
      scorers.flatMap((scorer) =>
        coordination[scorer]
          .filter((x) => x.score.score >= minCoordinate / 100)
          .flatMap((x) => x.page_ids)
      )
    );
  }, [coordination, minCoordinate]);

  return (
    <div className="coordination">
      {fundingEntities.length > 0 && (
        <React.Fragment>
          <FundingEntityCoordinationModal
            pageId={pageId}
            coordination={coordination["funding-entity"]}
            pageNames={pageNames}
            setColumnFilter={setColumnFilter}
          >
            {(toggle) => (
              <Badge
                css={css`
                  cursor: pointer;
                `}
                color="info"
                onClick={() => toggle(true)}
              >
                Entity: {fundingEntities.length}
              </Badge>
            )}
          </FundingEntityCoordinationModal>
        </React.Fragment>
      )}
      {hiddenCoordination.length > 0 && (
        <CoordinationModal
          pageId={pageId}
          coordination={coordination}
          pageNames={pageNames}
          setColumnFilter={setColumnFilter}
          facebookToken={facebookToken}
        >
          {(toggle) => (
            <Badge
              css={css`
                cursor: pointer;
              `}
              color="warning"
              onClick={() => toggle(true)}
            >
              Coordinate: {hiddenCoordination.length - 1}
            </Badge>
          )}
        </CoordinationModal>
      )}
    </div>
  );
};
