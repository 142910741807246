/** @jsxImportSource @emotion/react */
import { useCallback, useContext, useState } from "react";
import UserContext from "./UserContext";
import { getAPI, postAPI, RequestError } from "../../WebAPI";
import { sleep } from "../../util";
import { toastr } from "react-redux-toastr";
import { css } from "@emotion/core";
import useAsyncEffect from "use-async-effect";
import Worker from "workerize-loader!../workers/dataWorker"; // eslint-disable-line import/no-webpack-loader-syntax

export const dataWorker = Worker();

export const handleError = async (error) => {
  if (error instanceof RequestError) {
    try {
      const messages = await error.response.json();
      console.error("Request error", messages[0]);
      toastr.error("Request Error", messages[0]);
      return { message: "Request Error", detail: messages[0] };
    } catch {
      toastr.error("Unknown Error");
      return { message: "Unknown Error", detail: undefined };
    }
  } else {
    toastr.error("Request Error", error.message);
    return { message: "Request Error", detail: error.message };
  }
};

class TokenExpiredError extends Error {
  constructor(message) {
    super(message);
    this.name = "TokenExpiredError";
  }
}

async function pollResult(
  url,
  searchId,
  { token, interval = 500, statusCallback = undefined }
) {
  console.debug("Polling for result");

  while (true) {
    const { status, result_id, results, error_message, progress } =
      await getAPI("/api/search/results/", token, { searchId });

    if (status === "error") {
      if (error_message.includes("Session has expired")) {
        throw new TokenExpiredError(error_message);
      } else {
        throw new Error(error_message);
      }
    }

    if (status === "done") {
      console.debug("Result done, running dataWorker.inflateSearchResults");
      let inflated;
      try {
        inflated = await dataWorker.inflateSearchResults(results);
      } catch (error) {
        console.error("Error inflating search results", error);
        throw error;
      }

      toastr.success("Search Completed");
      return { results: inflated, resultId: result_id };
    }

    if (statusCallback) {
      statusCallback(progress);
    }

    await sleep(interval);
  }
}

export const useJSONL = (encoded) => {
  const [records, setRecords] = useState(null);

  useAsyncEffect(
    async (isMounted) => {
      if (encoded == null) {
        setRecords(null);
      } else {
        const decoded = await dataWorker.inflateSearchResults(encoded);
        if (!isMounted()) return;
        setRecords(decoded);
      }
    },
    [encoded]
  );

  return records;
};

export const SearchError = ({ message, detail }) => (
  <div>
    <h3
      css={css`
        color: red;
      `}
    >
      An error occurred while performing the search: {message}
    </h3>
    <div>{detail}</div>
  </div>
);

export function useSearch(url, { interval } = { interval: 500 }) {
  const { updateAuth, ...user } = useContext(UserContext);
  const { token } = user;
  const [results, setResults] = useState(null);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState([]);
  const [resultId, setResultId] = useState(null);

  const poll = useCallback(
    async (searchId) => {
      try {
        const { results, resultId } = await pollResult(
          "/api/search/results/",
          searchId,
          {
            token,
            interval,
            statusCallback: setProgress,
          }
        );
        setResults(results);
        setResultId(resultId);
      } catch (error) {
        if (error instanceof TokenExpiredError) {
          updateAuth({ ...user, facebookToken: null });
        }
        setResults(null);
        setError(await handleError(error));
        console.log(error);
      } finally {
        setPending(false);
      }
    },
    [token, interval, updateAuth, setResults, user, setError, setProgress]
  );

  const onSearch = useCallback(
    (query) => {
      async function performSearch(query) {
        setPending(true);
        setResults(null);
        setResultId(null);
        setError(null);
        try {
          toastr.info("Running Search");
          const { searchId } = await postAPI(url, query, { token });
          poll(searchId);
        } catch (error) {
          setError(await handleError(error));
          setPending(false);
        }
      }
      if (query) {
        performSearch(query);
      }
    },
    [token, url, poll, setError]
  );

  return [resultId, results, onSearch, pending, error, progress];
}
