/** @jsxImportSource @emotion/react */
import React from "react";

import URI from "urijs";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { DateFilter, MultiSelectColumnFilter } from "../common/filters";
import * as _ from "lodash";
import { firstBy } from "thenby";

import { CoordinationMarkers } from "./CoordinationModals";

function makeFacebookURL(snapshotURL, facebookToken) {
  return new URI(snapshotURL)
    .search((data) => ({ ...data, access_token: facebookToken }))
    .toString();
}

export const makeColumns = (
  facebookToken,
  flags = {},
  pageCoordination = {},
  pageNames = {},
  minCoordinate = 50,
  setColumnFilter = () => {}
) => [
  {
    Header: "Ad",
    accessor: "ad_snapshot_url",
    Cell: ({ cell: { value } }) => (
      <a
        href={makeFacebookURL(value, facebookToken)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faExternalLinkAlt} />
      </a>
    ),
    disableFilters: true,
    disableSorting: true,
    width: 45,
    default: true,
  },
  {
    Header: "Created",
    accessor: "ad_creation_time",
    // Example value: 2019-10-11T20:19:28+0000
    Cell: ({ cell: { value } }) => (
      <span>{moment(value).format("YYYY-MM-DD")}</span>
    ),
    Filter: DateFilter,
    filter: "dateRange",
    width: 100,
    default: true,
  },
  {
    Header: "Page",
    id: "page_id",
    accessor: (row) => ({ label: row.page_name, value: row.page_id }),
    Cell: ({ cell: { value } }) => (
      <React.Fragment>
        <span>
          <a
            href={`https://www.facebook.com/${value.value}/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value.label} <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
        </span>
        <CoordinationMarkers
          pageId={value.value}
          coordination={pageCoordination[value.value]}
          pageNames={pageNames}
          minCoordinate={minCoordinate}
          setColumnFilter={setColumnFilter}
          facebookToken={facebookToken}
        />
      </React.Fragment>
    ),
    Filter: MultiSelectColumnFilter,
    filter: "multiSelect",
    width: 175,
    sortType: firstBy((x) => x.values?.page_id?.label),
    default: true,
  },
  {
    Header: "Coord.",
    accessor: "coordination",
    disableSorting: true,
    default: true,
    width: 75,
    Filter: MultiSelectColumnFilter,
    filter: "multiSelect",
  },
  {
    Header: "CCY",
    accessor: "currency",
    Filter: MultiSelectColumnFilter,
    filter: "multiSelect",
    width: 75,
    default: true,
  },
  {
    Header: "Entity",
    accessor: "funding_entity",
    Filter: MultiSelectColumnFilter,
    filter: "multiSelect",
    width: 175,
    default: true,
  },
  ...(flags.includes("known-entities")
    ? [
        {
          Header: "Known Lists",
          id: "known",
          accessor: (row) =>
            _.sortBy(
              row.known.map((x) => ({ value: x.id, label: x.name })),
              (x) => x.label
            ),
          Cell: ({ cell: { value } }) => {
            return <span>{value.map((x) => x.label).join(", ")}</span>;
          },
          Filter: MultiSelectColumnFilter,
          filter: "multiSelect",
          width: 150,
          default: true,
          disableSorting: true,
        },
      ]
    : []),
  {
    Header: "Spend",
    accessor: "spend",
    Cell: ({ cell: { value } }) => (
      <span>{`${value.lower_bound}–${value.upper_bound}`}</span>
    ),
    disableFilters: true,
    width: 75,
    sortType: firstBy((x) => +x.values.spend.lower_bound),
    default: true,
  },
  {
    Header: "Impressions",
    accessor: "impressions",
    Cell: ({ cell: { value } }) => {
      if (value.upper_bound) {
        return <span>{`${value.lower_bound}–${value.upper_bound}`}</span>;
      } else {
        return <span>{value.lower_bound}+</span>;
      }
    },
    disableFilters: true,
    width: 125,
    sortType: firstBy((x) => +x.values.impressions.lower_bound),
    default: true,
  },
  {
    id: "region_distribution",
    Header: "Regions",
    accessor: (row) =>
      _.sortBy(row.region_distribution, [(x) => -x.percentage]).map(
        (x) => x.region
      ),
    Cell: ({ cell: { value } }) => <span>{value.join(", ")}</span>,
    disableSorting: true,
    Filter: MultiSelectColumnFilter,
    filter: "multiSelect",
    width: 250,
  },
  {
    Header: "Demographics",
    accessor: (row) =>
      _.sortBy(row.demographic_distribution, [(x) => -x.percentage]).map(
        (x) => `${_.capitalize(x.gender)} ${x.age}`
      ),
    Cell: ({ cell: { value } }) => <span>{value.join(", ")}</span>,
    Filter: MultiSelectColumnFilter,
    filter: "multiSelect",
    disableSorting: true,
    width: 250,
  },
  {
    Header: "Gender",
    accessor: (row) =>
      _.chain(row.demographic_distribution)
        .groupBy((x) => x.gender)
        .map((demographics, gender) => ({ gender, demographics }))
        .sortBy([(x) => -_.sum(x.demographics, "percentage")])
        .map((x) => _.capitalize(x.gender))
        .value(),
    Cell: ({ cell: { value } }) => <span>{value.join(", ")}</span>,
    Filter: MultiSelectColumnFilter,
    filter: "multiSelect",
    disableSorting: true,
    width: 150,
  },
  {
    Header: "Age",
    accessor: (row) =>
      _.chain(row.demographic_distribution)
        .groupBy((x) => x.age)
        .map((demographics, age) => ({ age, demographics }))
        .sortBy([(x) => -_.sum(x.demographics, "percentage")])
        .map((x) => x.age)
        .value(),
    Cell: ({ cell: { value } }) => <span>{value.join(", ")}</span>,
    Filter: MultiSelectColumnFilter,
    filter: "multiSelect",
    disableSorting: true,
    width: 150,
  },
  {
    Header: "Publisher Platforms",
    accessor: "publisher_platforms",
    Cell: ({ cell: { value } }) => (
      <span>{value.map(_.capitalize).join(", ")}</span>
    ),
    disableSorting: true,
    Filter: MultiSelectColumnFilter,
    filter: "multiSelect",
    width: 200,
  },
  {
    Header: "Link Caption",
    accessor: "ad_creative_link_caption",
    disableSorting: true,
    width: 200,
  },
  {
    Header: "Link Description",
    accessor: "ad_creative_link_description",
    disableSorting: true,
    width: 300,
  },
  {
    Header: "Creative",
    accessor: "ad_creative_body",
    disableSorting: true,
    width: 400,
    default: true,
  },
];
