import { useContext, useState } from "react";
import UserContext from "./UserContext";
import useAsyncEffect from "use-async-effect";
import { getAPI } from "../../WebAPI";

export function useEntityLists() {
  const { token } = useContext(UserContext);
  const [maps, setMaps] = useState({
    entityMap: new Map(),
    listMap: new Map(),
    pageMap: new Map(),
  });

  useAsyncEffect(
    async (isMounted) => {
      const lists = await getAPI("/api/known/", token, { full: 1 });

      const entityMap = new Map();
      const pageMap = new Map();
      const listMap = new Map();

      for (const list of lists) {
        listMap.set(list.id, list);

        for (const entity of list.facebook_entities) {
          if (!entityMap.has(entity)) {
            entityMap.set(entity, [list.id]);
          } else {
            entityMap.get(entity).push(list.id);
          }
        }

        for (const page of list.facebook_pages) {
          if (!entityMap.has(page.value)) {
            pageMap.set(page.value, [list.id]);
          } else {
            pageMap.get(page.value).push(list.id);
          }
        }

        if (!isMounted()) {
          return;
        }

        setMaps({ listMap, entityMap, pageMap });
      }
    },
    [token, setMaps]
  );

  return maps;
}
