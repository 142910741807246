/** @jsxImportSource @emotion/react */

import { Card, CardBody, CardHeader, CardTitle, Button } from "reactstrap";
import ChartErrorBoundary from "../common/ChartErrorBoundary";
import WordCloud from "../charts/WordCloud";
import DemographicChart from "./DemographicChart";
import AdMap from "./AdMap";
import { useContext, useMemo, useState } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import DomainChart from "./DomainChart";
import UrlChart from "./UrlChart";
import EntityChart from "./EntityChart";
import FlagContext from "../common/FlagContext";

const CHART_CONFIG = {
  "creative-cloud": {
    label: "Ad Creative Wordcloud",
    component: WordCloud,
  },
  "impression-demographics": {
    label: "Ad Imp. Demographics & Metrics",
    component: DemographicChart,
  },
  "impression-geography": {
    label: "Ad Impression Geography",
    component: AdMap,
  },
  "creative-domains": {
    label: "Most Common Domains in Creative",
    component: DomainChart,
  },
  "creative-urls": {
    label: "Most Common URLs in Creative",
    component: UrlChart,
  },
  entities: {
    label: "Most Common Funding Entities",
    component: EntityChart,
  },
};

const CHART_OPTIONS = Object.entries(CHART_CONFIG).map(([key, config]) => ({
  value: key,
  ...config,
}));

const ChartCard = ({ data, chartProps, setFilter, initialChart }) => {
  const { flags } = useContext(FlagContext);

  const [editing, setEditing] = useState(false);

  const [chart, setChart] = useState(initialChart);

  const [{ label }, Chart] = useMemo(() => {
    const config = CHART_CONFIG[chart];
    return [config, config.component];
  }, [chart]);

  return (
    <Card>
      <CardHeader>
        {editing ? (
          <div>
            <Select
              options={CHART_OPTIONS}
              defaultValue={CHART_OPTIONS.find((x) => x.value === chart)}
              onChange={(x) => {
                setChart(x.value);
                setEditing(false);
              }}
            />
          </div>
        ) : (
          <div className="d-flex">
            <div>
              <CardTitle>{label}</CardTitle>
            </div>
            {flags.includes("chart-config") && (
              <div
                className="ml-auto"
                onClick={() => setEditing(true)}
                style={{ cursor: "pointer" }}
              >
                <Button color="info" outline>
                  <FontAwesomeIcon icon={faList} />
                </Button>
              </div>
            )}
          </div>
        )}
      </CardHeader>
      <CardBody className="pt-0">
        <ChartErrorBoundary>
          <Chart data={data} {...chartProps} setFilter={setFilter} />
        </ChartErrorBoundary>
      </CardBody>
    </Card>
  );
};

export default ChartCard;
