import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { Bar } from "react-chartjs-2";
import Worker from "workerize-loader!../workers/chartWorker"; // eslint-disable-line import/no-webpack-loader-syntax

const worker = Worker();

const AgeChart = ({ data }) => {
  const [values, setValues] = useState({ labels: [], datasets: [] });

  useAsyncEffect(
    async (isMounted) => {
      const datasets = await worker.calculateGoogleAge(data);
      if (!isMounted()) return;
      setValues(datasets);
    },
    [data]
  );

  return (
    <Bar
      data={values}
      width={500}
      height={300}
      options={{
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                suggestedMin: 0, // minimum will be 0, unless there is a lower value.
              },
            },
          ],
        },
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
      }}
    />
  );
};

export default React.memo(AgeChart);
