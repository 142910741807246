/** @jsxImportSource @emotion/react */
import { css } from "@emotion/core";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  CustomFileInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useCallback, useMemo, useState } from "react";
import * as _ from "lodash";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./KnownEntities.scss";
import Papa from "papaparse";
import { toastr } from "react-redux-toastr";

const EntryCell = ({ name, readonly, onDelete }) => {
  const [show, setShow] = useState(false);
  return (
    <div
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      className="py-2"
    >
      {!readonly && onDelete && show && (
        <Button
          color="danger"
          className="mr-3"
          size="xs"
          onClick={() => onDelete(name)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      )}
      {name}
    </div>
  );
};

function parseFile(rows) {
  return rows.map((x, i) => {
    const entity = x["Funding Entity"];
    if (!entity) {
      throw new Error(`Row ${i + 2} missing Funding Entity`);
    }
    return entity;
  });
}

const FundingEntitiesCard = ({
  fundingEntities,
  readonly,
  onAdd,
  onDelete,
}) => {
  const [newValue, setNewValue] = useState("");
  const ordered = useMemo(() => _.sortBy(fundingEntities), [fundingEntities]);
  const [uploadSeq, setSeq] = useState(0);

  const upload = useCallback(
    (x) => {
      if (x.target.files.length) {
        setSeq((x) => x + 1);
        Papa.parse(x.target.files[0], {
          header: true,
          complete: (results) => {
            try {
              const entities = parseFile(results.data);
              onAdd(entities);
            } catch (ex) {
              toastr.error("Import Error", ex.toString());
            }
          },
        });
      }
    },
    [onAdd]
  );

  return (
    <Card>
      <CardHeader className="pb-1">
        <CardTitle tag="h5">Funding Entities</CardTitle>
      </CardHeader>
      <CardBody className="pt-1">
        {!readonly && (
          <Row>
            <Col className="mb-3">
              <Form inline>
                <FormGroup className="mr-2">
                  <Label for="watchlist-name" className="mr-2">
                    Add Entity
                  </Label>
                  <Input
                    css={css`
                      min-width: 300px;
                    `}
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                    name="fundingEntity"
                  />
                </FormGroup>
                <Button
                  disabled={newValue.trim() === ""}
                  onClick={(e) => {
                    e.preventDefault();
                    onAdd([newValue]);
                    setNewValue("");
                  }}
                >
                  Add
                </Button>

                <FormGroup className="ml-3">
                  <div
                    css={css`
                      width: 200px;
                    `}
                  >
                    <CustomFileInput
                      key={uploadSeq}
                      className="upload-input"
                      id="entitiesFile"
                      name="entitiesFile"
                      label="Import CSV"
                      onChange={upload}
                    />
                  </div>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        )}

        <Row>
          {!ordered.length && <Col>No funding entities selected</Col>}
          {ordered.map((x) => (
            <Col sm={3} className="mb-2" key={x}>
              <EntryCell name={x} readonly={readonly} onDelete={onDelete} />
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  );
};

export default FundingEntitiesCard;
