import React from "react";

class ChartErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
    if (window.rollbar) {
      window.rollbar.error(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return <div className="text-center">An error has occurred.</div>;
    }
    return this.props.children;
  }
}

export default ChartErrorBoundary;
