import { useCallback, useState } from "react";
import * as _ from "lodash";
import { liftValue } from "../../common/CustomSelect";

function useColumnFilters(
  pageNames,
  rawColumns,
  activeColumns,
  setActiveColumns
) {
  console.debug("useColumnFilters", { rawColumns, activeColumns });

  const [{ filterSetter, clearFilters }, setState] = useState({
    filterSetter: () => console.warn("Filtering not initialized"),
    clearFilters: () => console.warn("Clearing not initialized"),
  });

  const setFilterSetter = useCallback(
    (filterSetter) => {
      setState((x) => ({
        ...x,
        filterSetter: (column, config) => {
          console.debug(`Attempting to set filter for ${column}`, config);
          filterSetter(column, config);
        },
      }));
    },
    [setState]
  );

  const setClearFilters = useCallback(
    (clearFilters) => {
      setState((x) => ({ ...x, clearFilters }));
    },
    [setState]
  );

  const setColumnFilter = useCallback(
    (column, values) => {
      // ensure the column is visible/in the table so we can actually filter over it

      const def = rawColumns.find(
        (x) => column === x.id || column === x.accessor
      );

      if (!activeColumns.some((x) => x.value === def.Header)) {
        const columnOptions = rawColumns.map((x) => liftValue(x.Header));
        const activeHeaders = new Set(activeColumns.map((x) => x.value));
        const newActive = columnOptions.filter(
          (x) => activeHeaders.has(x.value) || x.value === def.Header
        );
        console.debug(`Activating column ${column} with header ${def.Header}`, {
          activeColumns,
          newActive,
        });
        setActiveColumns(newActive);
      }

      switch (column) {
        case "page_id": {
          filterSetter("page_id", {
            exclude: false,
            values: values.map((pageId) => ({
              value: pageId,
              label: pageNames[pageId],
            })),
          });
          return;
        }
        case "funding_entity": {
          console.log(
            `${column} -> ${_.castArray(values).map((x) => "'" + x + "'")}`
          );
          filterSetter("funding_entity", {
            exclude: false,
            values: _.castArray(values).map(liftValue),
          });
          return;
        }
        case "ad_creative_body": {
          console.log(`${column} -> ${values}`);
          filterSetter("ad_creative_body", {
            exclude: false,
            value: values,
          });
          return;
        }
        case "region_distribution": {
          console.log(`${column} -> ${values}`);
          filterSetter("region_distribution", {
            exclude: false,
            values: _.castArray(values).map(liftValue),
          });
          return;
        }
        default: {
          console.error(`Not implemented for column ${column}`);
        }
      }
    },
    [filterSetter, pageNames]
  );

  return { setColumnFilter, clearFilters, setFilterSetter, setClearFilters };
}

export default useColumnFilters;
