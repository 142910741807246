import { parse } from "json2csv";
import download from "downloadjs";

export function exportJSON(data) {
  const content = JSON.stringify(data, null, 2);
  download(new Blob([content]), "google-ads.json", "application/json");
}

export function exportAdCSV(data) {
  const flat = data.map((x) => ({
    ...x,
    campaigns: (x.campaign_id || []).join(", "),
    date_range_start: new Date(x.date_range_start).toLocaleDateString("en-US"),
    date_range_end: new Date(x.date_range_end).toLocaleDateString("en-US"),
  }));
  const content = parse(flat, {
    // excelStrings: true,
    withBOM: true,
    fields: [
      { label: "Ad ID", value: "ad_id" },
      { label: "Ad URL", value: "ad_url" },
      { label: "Ad Type", value: "ad_type" },
      { label: "Advertiser", value: "advertiser_name" },
      { label: "Advertiser ID", value: "advertiser_id" },
      { label: "First Impression", value: "date_range_start" },
      { label: "Last Impression", value: "date_range_end" },
      { label: "Spend Lower (USD)", value: "spend_range_min_usd" },
      { label: "Spend Upper (USD)", value: "spend_range_max_usd" },
      { label: "Impressions", value: "impressions" },
      { label: "Age Targeting", value: "age_targeting" },
      { label: "Gender Targeting", value: "gender_targeting" },
      { label: "Geo Targeting Included", value: "geo_targeting_included" },
      { label: "Geo Targeting Excluded", value: "geo_targeting_excluded" },
      { label: "Regions", value: "regions" },
    ],
  });
  download(new Blob([content]), "google-ads.csv", "text/csv");
}
