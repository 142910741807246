import React from 'react';
import {connect} from 'react-redux';
import {toggleSidebar} from '../redux/actions/sidebarActions';

import {Navbar} from 'reactstrap';

const NavbarComponent = ({ dispatch }) => {
  return (
    <Navbar color="white" light expand>
      <span
        className="sidebar-toggle d-flex mr-2"
        onClick={() => {
          dispatch(toggleSidebar());
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      {/*<Form inline>*/}
      {/*  <Input*/}
      {/*    type="text"*/}
      {/*    placeholder="Search projects..."*/}
      {/*    aria-label="Search"*/}
      {/*    className="form-control-no-border mr-sm-2"*/}
      {/*  />*/}
      {/*</Form>*/}

      {/*<Collapse navbar>*/}
      {/*  <Nav className="ml-auto" navbar>*/}
      {/*    <NavbarDropdown*/}
      {/*      header="New Messages"*/}
      {/*      footer="Show all messages"*/}
      {/*      icon={MessageCircle}*/}
      {/*      count={messages.length}*/}
      {/*      showBadge*/}
      {/*    >*/}
      {/*      {messages.map((item, key) => {*/}
      {/*        return (*/}
      {/*          <NavbarDropdownItem*/}
      {/*            key={key}*/}
      {/*            icon={*/}
      {/*              <img*/}
      {/*                className="avatar img-fluid rounded-circle"*/}
      {/*                src={item.avatar}*/}
      {/*                alt={item.name}*/}
      {/*              />*/}
      {/*            }*/}
      {/*            title={item.name}*/}
      {/*            description={item.description}*/}
      {/*            time={item.time}*/}
      {/*            spacing*/}
      {/*          />*/}
      {/*        );*/}
      {/*      })}*/}
      {/*    </NavbarDropdown>*/}

      {/*    <NavbarDropdown*/}
      {/*      header="New Notifications"*/}
      {/*      footer="Show all notifications"*/}
      {/*      icon={BellOff}*/}
      {/*      count={notifications.length}*/}
      {/*    >*/}
      {/*      {notifications.map((item, key) => {*/}
      {/*        let icon = <Bell size={18} className="text-warning" />;*/}

      {/*        if (item.type === "important") {*/}
      {/*          icon = <AlertCircle size={18} className="text-danger" />;*/}
      {/*        }*/}

      {/*        if (item.type === "login") {*/}
      {/*          icon = <Home size={18} className="text-primary" />;*/}
      {/*        }*/}

      {/*        if (item.type === "request") {*/}
      {/*          icon = <UserPlus size={18} className="text-success" />;*/}
      {/*        }*/}

      {/*        return (*/}
      {/*          <NavbarDropdownItem*/}
      {/*            key={key}*/}
      {/*            icon={icon}*/}
      {/*            title={item.title}*/}
      {/*            description={item.description}*/}
      {/*            time={item.time}*/}
      {/*          />*/}
      {/*        );*/}
      {/*      })}*/}
      {/*    </NavbarDropdown>*/}

      {/*    <UncontrolledDropdown nav inNavbar className="mr-2">*/}
      {/*      <DropdownToggle nav caret className="nav-flag">*/}
      {/*        <img src={usFlag} alt="English" />*/}
      {/*      </DropdownToggle>*/}
      {/*      <DropdownMenu right>*/}
      {/*        <DropdownItem>*/}
      {/*          <img*/}
      {/*            src={usFlag}*/}
      {/*            alt="English"*/}
      {/*            width="20"*/}
      {/*            className="align-middle mr-1"*/}
      {/*          />*/}
      {/*          <span className="align-middle">English</span>*/}
      {/*        </DropdownItem>*/}
      {/*        <DropdownItem>*/}
      {/*          <img*/}
      {/*            src={esFlag}*/}
      {/*            alt="Spanish"*/}
      {/*            width="20"*/}
      {/*            className="align-middle mr-1"*/}
      {/*          />*/}
      {/*          <span className="align-middle">Spanish</span>*/}
      {/*        </DropdownItem>*/}
      {/*        <DropdownItem>*/}
      {/*          <img*/}
      {/*            src={deFlag}*/}
      {/*            alt="German"*/}
      {/*            width="20"*/}
      {/*            className="align-middle mr-1"*/}
      {/*          />*/}
      {/*          <span className="align-middle">German</span>*/}
      {/*        </DropdownItem>*/}
      {/*        <DropdownItem>*/}
      {/*          <img*/}
      {/*            src={nlFlag}*/}
      {/*            alt="Dutch"*/}
      {/*            width="20"*/}
      {/*            className="align-middle mr-1"*/}
      {/*          />*/}
      {/*          <span className="align-middle">Dutch</span>*/}
      {/*        </DropdownItem>*/}
      {/*      </DropdownMenu>*/}
      {/*    </UncontrolledDropdown>*/}

      {/*    /!*<UncontrolledDropdown nav inNavbar>*!/*/}
      {/*    /!*  <span className="d-inline-block d-sm-none">*!/*/}
      {/*    /!*    <DropdownToggle nav caret>*!/*/}
      {/*    /!*      <Settings size={18} className="align-middle" />*!/*/}
      {/*    /!*    </DropdownToggle>*!/*/}
      {/*    /!*  </span>*!/*/}
      {/*    /!*  <span className="d-none d-sm-inline-block">*!/*/}
      {/*    /!*    <DropdownToggle nav caret>*!/*/}
      {/*    /!*      <img*!/*/}
      {/*    /!*        src={avatar1}*!/*/}
      {/*    /!*        className="avatar img-fluid rounded-circle mr-1"*!/*/}
      {/*    /!*        alt="Chris Wood"*!/*/}
      {/*    /!*      />*!/*/}
      {/*    /!*      <span className="text-dark">Chris Wood</span>*!/*/}
      {/*    /!*    </DropdownToggle>*!/*/}
      {/*    /!*  </span>*!/*/}
      {/*    /!*  <DropdownMenu right>*!/*/}
      {/*    /!*    <DropdownItem>*!/*/}
      {/*    /!*      <User size={18} className="align-middle mr-2" />*!/*/}
      {/*    /!*      Profile*!/*/}
      {/*    /!*    </DropdownItem>*!/*/}
      {/*    /!*    <DropdownItem>*!/*/}
      {/*    /!*      <PieChart size={18} className="align-middle mr-2" />*!/*/}
      {/*    /!*      Analytics*!/*/}
      {/*    /!*    </DropdownItem>*!/*/}
      {/*    /!*    <DropdownItem divider />*!/*/}
      {/*    /!*    <DropdownItem>Settings & Privacy</DropdownItem>*!/*/}
      {/*    /!*    <DropdownItem>Help</DropdownItem>*!/*/}
      {/*    /!*    <DropdownItem>Sign out</DropdownItem>*!/*/}
      {/*    /!*  </DropdownMenu>*!/*/}
      {/*    /!*</UncontrolledDropdown>*!/*/}
      {/*  </Nav>*/}
      {/*</Collapse>*/}
    </Navbar>
  );
};

export default connect(store => ({
  app: store.app
}))(NavbarComponent);
