import React from "react";
import { Button } from "reactstrap";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
    if (window.rollbar) {
      window.rollbar.error(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="text-center">
          <h1 className="display-1 font-weight-bold">500</h1>
          <p className="h1">Application error.</p>
          <p className="h2 font-weight-normal mt-3 mb-4">
            The web application encountered something unexpected and crashed.
            Try reloading the page.
          </p>
          <Button
            color="primary"
            size="lg"
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload page
          </Button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
