import React, { useCallback, useMemo, useState } from "react";
import ChoroplethMap from "../charts/ChoroplethMap";
import useAsyncEffect from "use-async-effect";
import { HorizontalBar } from "react-chartjs-2";
import Worker from "workerize-loader!../workers/chartWorker"; // eslint-disable-line import/no-webpack-loader-syntax

const worker = Worker();

const AdMap = ({ data, countries = [], setFilter }) => {
  const showState = useMemo(
    () => countries.length === 1 && countries[0].value === "US",
    [countries]
  );

  const [dataset, setDataset] = useState(
    showState ? [] : { labels: [], datasets: [] }
  );

  const onSelect = useCallback(
    (geography) => {
      setFilter("region_distribution", [geography]);
    },
    [setFilter]
  );

  useAsyncEffect(
    async (isMounted) => {
      const choropleth = await worker.makeChoropleth(data, showState);
      const dataset = showState
        ? choropleth
        : {
            labels: choropleth.map(([name]) => name),
            datasets: [
              {
                label: "Ad Impressions",
                backgroundColor: choropleth.map(() => "rgb(56, 108, 176)"),
                data: choropleth.map(([, value]) => Math.round(value)),
              },
            ],
          };
      if (!isMounted()) return;
      setDataset(dataset);
    },
    [data, countries]
  );

  return showState ? (
    <ChoroplethMap data={dataset} onSelect={onSelect} />
  ) : (
    <HorizontalBar
      data={dataset}
      width={100}
      height={300}
      options={{
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
      }}
    />
  );
};

export default React.memo(AdMap);
