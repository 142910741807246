import Humanize from "humanize-plus";

const Range = ({ range }) => {
  if (!range) {
    return "–";
  }
  const [lower, upper] = range;
  if (Number.isNaN(upper)) {
    return `${Humanize.intComma(lower)}+`;
  } else {
    return `${Humanize.intComma(lower)} – ${Humanize.intComma(upper)}`;
  }
};

export default Range;
