import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { DateFilter, MultiSelectColumnFilter } from "../common/filters";
import * as _ from "lodash";
import { firstBy } from "thenby";

const unionAccessor = (field, delimiter = ",") => (row) =>
  _.uniq(row[field].split(delimiter).map((x) => x.trim()));

const JoinCell = ({ cell: { value } }) => (
  <span>{_.castArray(value).join(", ")}</span>
);

export const CURRENCIES = [
  "USD",
  "EUR",
  "INR",
  "BGN",
  "HRK",
  "CZK",
  "DKK",
  "HUF",
  "PLN",
  "RON",
  "SEK",
  "GBP",
];

const COMMON_COLUMNS = {
  advertiser: {
    Header: "Advertiser",
    accessor: (row) => ({
      value: row.advertiser_id,
      label: row.advertiser_name,
    }),
    id: "advertiser_id",
    Cell: ({ cell: { value } }) => (
      <a
        href={`https://transparencyreport.google.com/political-ads/advertiser/${value.value}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {value.label} <FontAwesomeIcon icon={faExternalLinkAlt} />
      </a>
    ),
    sortType: firstBy((x) => x.values?.advertiser_id?.label),
    Filter: MultiSelectColumnFilter,
    filter: "multiSelect",
    width: 175,
    default: true,
  },
};

function makeRange(lower, upper) {
  if (lower == null && upper == null) {
    return <span>No Data</span>;
  } else if (lower == null && upper) {
    return <span>&le;{upper}</span>;
  } else if (upper == null && lower) {
    return <span>&ge;{lower}</span>;
  } else {
    return (
      <span>
        {lower}–{upper}
      </span>
    );
  }
}

export const makeAdColumns = () => [
  {
    Header: "Ad",
    accessor: "ad_url",
    Cell: ({ cell: { value } }) => (
      <a href={value} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faExternalLinkAlt} />
      </a>
    ),
    disableFilters: true,
    disableSorting: true,
    width: 40,
    default: true,
  },
  {
    Header: "Type",
    accessor: "ad_type",
    Filter: MultiSelectColumnFilter,
    filter: "multiSelect",
    width: 90,
    default: true,
  },
  {
    Header: "Start",
    accessor: "date_range_start",
    Cell: ({ cell: { value } }) => (
      <span>{moment(value).format("YYYY-MM-DD")}</span>
    ),
    Filter: DateFilter,
    filter: "dateRange",
    width: 100,
    default: true,
  },
  {
    Header: "End",
    accessor: "date_range_end",
    Cell: ({ cell: { value } }) => (
      <span>{moment(value).format("YYYY-MM-DD")}</span>
    ),
    Filter: DateFilter,
    filter: "dateRange",
    width: 100,
    default: true,
  },
  COMMON_COLUMNS.advertiser,
  ...CURRENCIES.map((ccy) => ({
    Header: `Spend (${ccy})`,
    accessor: (row) => ({
      lower_bound: row[`spend_range_min_${ccy.toLowerCase()}`],
      upper_bound: row[`spend_range_max_${ccy.toLowerCase()}`],
    }),
    Cell: ({ cell: { value } }) =>
      makeRange(value.lower_bound, value.upper_bound),
    sortType: firstBy((x) => +x.values[`spend_range_min_${ccy.toLowerCase()}`]),
    disableFilters: true,
    width: 75,
    default: ccy === "USD",
  })),
  {
    Header: "Impressions",
    accessor: "impressions",
    Filter: MultiSelectColumnFilter,
    filter: "multiSelect",
    width: 125,
    default: true,
  },
  {
    Header: "Regions",
    accessor: unionAccessor("regions"),
    Filter: MultiSelectColumnFilter,
    filter: "multiSelect",
    width: 125,
  },
  {
    Header: "Gender Targeting",
    accessor: unionAccessor("gender_targeting"),
    Cell: JoinCell,
    width: 150,
    Filter: MultiSelectColumnFilter,
    filter: "multiSelect",
    default: true,
  },
  {
    Header: "Age Targeting",
    accessor: unionAccessor("age_targeting"),
    Cell: JoinCell,
    width: 150,
    Filter: MultiSelectColumnFilter,
    filter: "multiSelect",
    default: true,
  },
  {
    Header: "Incl. Geography",
    accessor: unionAccessor("geo_targeting_included", ", "),
    Cell: JoinCell,
    Filter: MultiSelectColumnFilter,
    filter: "multiSelect",
    width: 200,
    default: true,
  },
  {
    Header: "Excl. Geography",
    accessor: unionAccessor("geo_targeting_excluded", ", "),
    Cell: JoinCell,
    Filter: MultiSelectColumnFilter,
    filter: "multiSelect",
    width: 200,
  },
];
