import React, { useState } from "react";
import ReactWordCloud from "react-wordcloud";
import useAsyncEffect from "use-async-effect";

import Worker from "workerize-loader!../workers/chartWorker"; // eslint-disable-line import/no-webpack-loader-syntax

const worker = Worker();

const WordCloud = ({ data, maxWords = 100, setFilter }) => {
  const [words, setWords] = useState([]);

  useAsyncEffect(
    async (isMounted) => {
      const words = await worker.calculateTopWords(data, maxWords);
      if (!isMounted()) return;
      setWords(words);
    },
    [data, maxWords]
  );

  const callbacks = {
    onWordClick: ({ text }) => setFilter("ad_creative_body", text),
  };

  const options = {
    rotations: 0,
    rotationAngles: [0, 90],
    transitionDuration: 0,
    fontSizes: [12, 24],
    deterministic: true,
    scale: "log",
  };
  return (
    <ReactWordCloud words={words} options={options} callbacks={callbacks} />
  );
};

export default React.memo(WordCloud);
