/** @jsxImportSource @emotion/react */

import { Row, Col } from "reactstrap";
import React, { useCallback, useContext, useState } from "react";
import KnownEntitySettingsCard from "./KnownEntitySettingsCard";
import FundingEntitiesCard from "./FundingEntitiesCard";
import PagesCard from "./PagesCard";
import { patchAPI } from "../../WebAPI";
import UserContext from "../common/UserContext";
import { toastr } from "react-redux-toastr";
import produce from "immer";

const KnownEntitiesDetail = ({ entityList }) => {
  const { token } = useContext(UserContext);
  const [entities, setFundingEntities] = useState(entityList.facebook_entities);
  const [pages, setPages] = useState(entityList.facebook_pages);

  const addEntity = useCallback(
    (names) => {
      setFundingEntities((current) => {
        const existing = new Set(current);
        return produce(current, (draft) => {
          for (const name of names) {
            if (!existing.has(name)) {
              draft.push(name);
            }
          }
        });
      });
    },
    [setFundingEntities]
  );

  const deleteEntity = useCallback(
    (name) => {
      setFundingEntities((current) => current.filter((x) => x !== name));
    },
    [setFundingEntities]
  );

  const addPage = useCallback(
    (pages) => {
      setPages((current) => {
        const existing = new Set(current.map((x) => x.value));
        return produce(current, (draft) => {
          for (const page of pages) {
            if (!existing.has(page.value)) {
              draft.push(page);
            }
          }
        });
      });
    },
    [setPages]
  );

  const deletePage = useCallback(
    (pageId) => {
      setPages((current) => current.filter((x) => x.value !== pageId));
    },
    [setPages]
  );

  const onSave = useCallback(
    async ({ name, universal }) => {
      try {
        await patchAPI(
          `/api/known/${entityList.id}/`,
          {
            name,
            universal,
            facebook_pages: pages,
            facebook_entities: entities,
          },
          { token }
        );
        toastr.success(
          "Updated List",
          `Updated known entity list watchlist ${name}`
        );
      } catch (ex) {
        toastr.error("Error", `Error saving list: ${ex.toString()}`);
      }
    },
    [entities, pages, entityList, token]
  );

  return (
    <React.Fragment>
      <Row>
        <Col>
          {entityList.can_edit ? (
            <KnownEntitySettingsCard entityList={entityList} onSave={onSave} />
          ) : (
            <h2>{entityList.name}</h2>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <PagesCard
            pages={pages}
            onAdd={addPage}
            onDelete={deletePage}
            readonly={!entityList.can_edit}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FundingEntitiesCard
            fundingEntities={entities}
            onAdd={addEntity}
            onDelete={deleteEntity}
            readonly={!entityList.can_edit}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default KnownEntitiesDetail;
